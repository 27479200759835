.panel-widget--collapse-indicator {
  align-items: center;
  background-color: $brand-crimson-red;
  border-radius: 50%;
  display: flex;
  flex-basis: 64px;
  height: 64px;
  justify-content: center;
  margin-left: auto;
  width: 64px;

  .wizicon {
    transform: rotate(0);
    transition: transform 0.3s;

    path {
      stroke: $brand-white;
    }
  }

  @media screen and (max-width: $screen-md) {
    flex-basis: 32px;
    height: 32px;
    width: 32px;
  }
}

.panel--web_publication_collapse.in .panel-widget--collapse-indicator .wizicon {
  transform: rotate(90deg);
}

.in .panel-widget--collapse-indicator .wizicon {
  transform: rotate(90deg);
}

.panel--expert_expertise,
.panel--web_publication_collapse {
  .panel-heading {
    background-color: $brand-royal-blue;
  }

  .panel-widget--collapse-index {
    display: none;

    .panel-widget-item {
      @include size(59px, 59px);
      @include make-font-title--4();

      line-height: 1;
      padding: 20px;
      text-align: right;
    }
  }

  .panel-widget-item {
    padding: 0;
  }

  &.in {
    .panel-collapse {
      background-color: $brand-royal-blue;
    }
  }

  &.panel--highlight {
    .panel-title,
    .panel-widget--collapse-index .panel-widget-item {
      color: $brand-white;
    }
  }

  .collapse-body {
    color: $brand-white;
    font-family: $font-family-primary;
    letter-spacing: $letter-spacing;
    line-height: 23.4px;
    padding: 0 32px 32px;
    word-wrap: break-word;
  }
}

.panel--expert_expertise {
  &:after {
    left: 0;
  }

  .panel-widget--dates {
    @include make-font-9();
    float: left;
    font-weight: $font-weight-bold;
    text-transform: uppercase;
    width: 220px;

    @media screen and (max-width: $grid-float-breakpoint) {
      float: none;
    }
  }

  .panel-body {
    padding-left: 220px;

    @media screen and (max-width: $grid-float-breakpoint) {
      padding-left: 20px;
    }
  }

  &.in {
    .panel-heading .wizicon-collapse-indicator {
      transform: rotate(-180deg);
      transition: transform ease-in 0.3s;
    }
  }

  &:last-child:after {
    content: none;
  }
}

.panel--web_publication_collapse {
  &:after {
    left: 60px;
  }
}

.panel-group--program {
  .panel--program:last-child .panel-title {
    margin-bottom: 0;
  }
}

.panel--program {
  .panel-heading {
    background-color: $brand-royal-blue;
  }

  .panel-title {
    .panel-title-icon {
      float: right;

      .wizicon {
        fill: $brand-dark;
      }
    }
  }

  .panel-body {
    background-color: $brand-royal-blue;;
    padding: 0;
  }

  .panel--subprogram {
    .panel-heading {
      background-color: $brand-royal-blue;

      .panel-title {
        display: flex;
        align-items: center;
        color: $brand-white;
        font-family: $font-family-primary;
        letter-spacing: $letter-spacing;
        word-wrap: break-word;

        .panel-title--dates {
          font-size: $font-size-base;
          line-height: 23.4px;
        }

        .panel-title-text {
          font-family: $font-family-bold;
          font-size: $font-size-slg;
          line-height: 36.4px;
          margin-left: 48px;
          margin-right: 5px;
        }
      }

      .panel-widget--dates {
        color: $brand-primary;
        float: left;
        width: 18%;

        .panel-widget-item {
          padding: 20px;
          display: flex;
          align-items: center;
        }

        .wizicon {
          @include size(13px, 13px);
          color: $brand-primary;
          fill: $brand-primary;
          line-height: 1;
        }
      }

      .panel-widget--performers {
        float: right;

        .mediaobj--performer img {
          width: 30px;
        }

        .panel-widget-item {
          padding: 10px 5px;
        }
      }

      .panel-widget--collapse-indicator {
        float: right;
        transition: transform 0.3s ease-out;
      }

      @media screen and (max-width: $screen-md) {
        a[data-toggle="collapse"] {
          padding: 16px;
        }

        .panel-title {
          .panel-title--dates {
            flex-basis: 37%;
            font-size: $font-size-sm;
            line-height: 19.2px;
          }

          .panel-title-text {
            flex: 1;
            font-size: $font-size-base;
            line-height: 23.4px;
            margin-left: 6px;
            margin-right: 6px;
          }
        }
      }
    }

    .panel-collapse {
      .panel-body {
        padding: 0 32px 32px;

        .panel-text {
          color: $brand-white;

          a {
            color: $brand-white;
            padding: 0;
            text-decoration: none;

            &::before {
              content: none;
            }
          }
  
          ul {
            padding: 0;
  
            li {
              color: $text-color;
              font-family: $font-family-primary;
              padding-left: 0;
  
              &::before {
                content: none;
              }
            }
          }
        }
      }
    }
  }

  &.in {
    .panel-widget--collapse-indicator {
      .wizicon {
        transform: rotate(0);
      }
    }

    .panel--subprogram.in {
      .panel-widget--collapse-indicator  .wizicon {
        transform: rotate(90deg);
        transition: transform 0.3s ease-in;
      }
    }
  }
}

.panel--content-download {
  margin: 0 auto;
  width: fit-content;

  .panel-heading {
    background: transparent;
    border: none;

    @media screen and (min-width: $screen-md) {
      &:hover {
        .panel-title {
          background: $brand-primary;
          color: $brand-white;
        }
      }
    }
  }

  a.panel-title {
    padding: 25px 32px;
  }

  .panel-title {
    @include make-font-9();

    align-items: center;
    background-color: $brand-crimson-red;
    border-color: $brand-crimson-red;
    display: flex;
    justify-content: center;
    text-align: center;
    text-transform: uppercase;

    .wizicon {
      height: 20px;
      margin-left: 16px;
      width: 20px;
    }
  }

  &.in .panel-title {
    background: $brand-primary;
    color: $brand-white;

    .wizicon {
      fill: $brand-white;
    }
  }

  .panel-collapse {
    background-color: $brand-white;
    position: absolute;
    width: 100%;
    z-index: 2;
  }
}

.navbar {
  border-radius: 0;
  border-width: 0;
}

.navbar-content,
.navbar-element {
  @include clearfix();
}

.navbar-element--main {
  margin: 0 -28px;

  @media screen and (max-width: $screen-md) {
    margin: 0;
  }
}

@media screen and (max-width: $grid-float-breakpoint-max) {
  .navbar-nav {
    margin-bottom: 0;
    margin-top: 0;
  }
}

.navbar--app {
  background-color: $brand-light;
  border-width: 0;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.04);
}

.navbar-brand {
  border-right: 1px solid $border-color;
  display: block;
  float: none;
  padding: 0;
  position: relative;

  .navbar-brand-img {
    display: block; // Overrides common/style.css
    height: auto; // Overrides common/style.css
    margin: auto;
    max-width: 100%;
  }

  svg.navbar-brand-img {
    display: block;
    fill: #7E8083;
    height: 50px;
    margin: auto;
    padding: 10px 15px;
    width: 170px;
  }
}

.navbar--channel {
  border: none;

  .container {
    padding: 0;
    width: 100%;
  }

  .nav--channel-1 {
    @include make-nav-variant(
      $brand-primary, // $link-color,
      $brand-primary, // $link-color-hover,
      transparent, // $link-bg,
      transparent, // $link-bg-hover,
      $brand-primary, // $link-color-active,
      transparent, // $link-bg-active,
      $text-muted, // $link-color-disabled,
      transparent, // $link-bg-disabled,
    );

    margin: 0;
    width: 100%;

    .nav-link {
      align-items: center;
      color: $brand-primary;
      display: flex;
      font-family: $font-family-bold;
      font-size: $font-size-xs;
      font-weight: unset;
      letter-spacing: $letter-spacing;
      line-height: 16.8px;
      padding: 24px 28px;
      white-space: normal;

      .nav-link-text {
        vertical-align: middle;
      }

      .wizicon {
        @include size(13px, 13px);

        margin-left: 5px;
        vertical-align: middle; // TODO: there's probably a better thing to do.

        path {
          fill: $brand-primary;
        }
      }

      &:hover {
        color: $brand-primary;
      }
    }

    .offie--active.nav-link .wizicon,
    .active > .nav-link .wizicon {
      fill: $brand-primary;
    }

    @media screen and (min-width: $screen-md) {
      > .nav-item {
        &.active > .nav-link:after,
        > .nav-link.offie--active:after,
        > .nav-link:hover:after {
          opacity: 1;
        }

        > .offie-dropdown {
          width: 100%;
        }
      }
    }
  }

  .nav--channel-2 {
    .nav-link {
      padding-bottom: 10px;
      padding-top: 10px;
      text-transform: none;
    }
  }
}

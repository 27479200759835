// Variants.
@mixin card-variant($bg, $color, $color-muted) {
  background-color: $bg;
  color: $color;

  a {
    &,
    &:hover,
    &:focus {
      color: $color;
    }
  }

  .card-subtitle {
    color: $color-muted;
  }

  .label-primary {
    color: $color;
  }
}

@mixin card-variant-simple($card-bg, $card-header-bg, $card-footer-bg: $card-header-bg) {
  .card-header {
    background-color: $card-header-bg;
  }

  .card-block {
    background-color: $card-bg;
  }
}

// Sizing.
@mixin card-sizing($card-spacer-x, $card-spacer-y) {
  .card {
    margin-bottom: 0; // Resets CSSR.
  }

  .card-title,
  .card-separator,
  .card-subtitle,
  .card-text,
  .card-element {
    margin-bottom: 0;
    margin-top: 0;
  }

  .card-subtitle {
    margin-top: 0;
  }

  .card-block {
    padding: $card-spacer-y $card-spacer-x;
  }
}

@mixin make-card-layout--reset() {
  .card-img {
    float: none;
    margin-bottom: $card-spacer-y;
    margin-right: 0;
    width: auto;
  }

  .card-block {
    overflow: visible;
  }
}

@mixin make-card-layout--list($width-left, $breakpoint: $grid-float-breakpoint) {
  @media screen and (min-width: $breakpoint) {
    @include clearfix();

    .card-img {
      margin-bottom: 0;
      margin-right: $card-spacer-x * 2;
      width: $width-left;
    }

    .card-block {
      overflow: hidden; // Clears the float.
      padding: 0;
    }
  }
}

@mixin card-title-decorator($color, $height, $width, $leftOffset: 'auto', $rightOffset: 'auto') {
  padding-bottom: $card-spacer-y;
  position: relative;

  &:after {
    background-color: $brand-primary;
    bottom: 0;
    content: '';
    height: $height;
    position: absolute;
    left: $leftOffset;
    right: $rightOffset;
    width: $width;
  }
}

@mixin card-img-decorator() {
  .card-img-wrapper {
    height: 0;
    padding: 0 0 100%;
    position: relative;
    overflow: hidden;
    border-top-left-radius: 30px;
    border: 1px solid transparent;
      -moz-transform: translateY(17%) skewY(-17deg) rotate(0.000001deg);
      -webkit-transform: translateY(17%) skewY(-17deg) rotate(0.000001deg);
    transform: translateY(17%) skewY(-17deg) rotate(0.000001deg);
    transition: .2s ease-in-out;
  }

  .card-img-inner {
    display: -webkit-box;  /* OLD - iOS 6-, Safari 3.1-6, BB7 */
    display: -ms-flexbox;  /* TWEENER - IE 10 */
    display: -webkit-flex; /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
    display: flex;         /* NEW, Spec - Firefox, Chrome, Opera */

    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  .card-img {
    img {
      flex-shrink: 0;
      width: auto;
      height: auto;
      min-width: 100%;
      min-height: 100%;
      max-width: 100%;
      -webkit-transform: translateY(-17%) skewY(17deg) rotate(0.000001deg);
      transform: translateY(-17%) skewY(17deg) rotate(0.000001deg);
      transition: .2s ease-in-out;
    }
  }

  .card-block {
    &:after {
      display: block;
      content: '';
      height: 200px;
      position: absolute;
      top: 0;
      left: -5px;
      right: -5px;
      border-top-left-radius: 40px;
      background: #fff;
      -webkit-transform: translateY(0) skewY(-17deg);
      transform: translateY(0) skewY(-17deg);
      transition: .2s ease-in-out;
      z-index: -1;
    }
  }
}

.btn {
  @include button-size($btn-font-size, 18px, $btn-font-size, 1, 0);

  letter-spacing: 0.095em;
  text-decoration: none;
  text-transform: uppercase;

  &:active {
      -webkit-box-shadow: inset 0 3px 15px rgba(0, 0, 0, .05);
    box-shadow: inset 0 3px 15px rgba(0, 0, 0, .05);
  }

  .wizicon {
    @include size($btn-font-size, $btn-font-size);
    vertical-align: bottom;
  }
}

.btn-primary,
.btn-secondary {
  .wizicon {
    fill: #fff;
  }
}

.btn--secondary {
  @include button-huer(
    $brand-white, // color
    $brand-white, //color hover
    $brand-red, //bg
    $brand-coral, //bg-hover
    $brand-red, //border
    $brand-coral, //border hover
  );

  align-items: center;
  color: $brand-white;
  display: flex;
  font-size: $font-size-xs;
  letter-spacing: $letter-spacing;
  line-height: 16px;
  padding: 25px 32px;
  text-transform: uppercase;
  width: fit-content;

  .section-btn-icon {
    align-items: center;
    display: flex;
    margin-left: 16px;

    .wizicon {
      height: 20px;
      width: 20px;

      path {
        fill: $brand-white;
      }
    }
  }
}

#didomi-banner-btn {
  background-color: $brand-white;
  font-family: $font-family-primary;
  font-size: $font-size-xs;
  border-radius: 0;
  border-width: 1px;
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 99;
}

.form {
  .section-btn {
    align-items: center;
    background-color: $brand-crimson-red;
    border-color: $brand-crimson-red;
    border-radius: 0;
    color: $brand-white;
    display: flex;
    justify-content: center;
    letter-spacing: $letter-spacing;
    margin: 24px auto 0;
    padding: 24px 32px;
    position: relative;
    width: fit-content;

    &:hover {
      background-color: $brand-primary;
      border-color: $brand-primary;
    }
  }

  ::placeholder {
    color: $text-color;
  }

  textarea {
    resize: none;
  }
}

.help-block {
  color: $brand-red;
  font-size: $font-size-xs;
  line-height: 16px;
  margin-top: 12px;
  position: absolute;

  @media screen and (max-width: $screen-md) {
    font-size: $font-size-xxs;
  }
}

.form--name,
.form--email {
  width: 100%;
}

.form-group {
  margin-bottom: 24px;
  padding-bottom: 8px;
  position: relative;

  &:has(input[type=text])::after,
  &:has(input[type=email])::after,
  &:has(input[type=tel])::after,
  &:has(textarea)::after {
    background-image: url("../../../images/border-input.png");
    background-position: bottom center;
    background-repeat: no-repeat;
    background-size: cover;
    bottom: 0;
    content: "";
    height: 8px;
    position: absolute;
    width: 100%;
  }

  &.form-group--captcha::after {
    content: none;
  }

  > label {
    color: $text-color;
    font-family: $font-family-bold;
    font-size: $font-size-slg;
    font-weight: unset;
    letter-spacing: $letter-spacing;
    line-height: 36.4px;
    margin-bottom: 8px;
  }

  .form-control {
    border-radius: 0;
    border: 1px solid $brand-primary;
    font-family: $font-family-primary;
    font-size: $font-size-base;
    font-weight: unset;
    letter-spacing: $letter-spacing;
    line-height: 23.4px;
    padding: 16px 24px;
    position: relative;
  }

  .form-control:not(textarea) {
    height: 57px;
  }

  &.form-group--eula {
    margin-top: 24px;
    padding-bottom: 0;

    .checkbox {
      margin: 0;

      p {
        color: $text-color;
      }

      .lab-checkbox {
        padding-left: 24px;
      }

      label {
        align-items: flex-start;
        display: flex;
        flex-direction: column;
        font-family: $font-family-primary;
        font-size: $font-size-sm;
        font-weight: unset;
        letter-spacing: $letter-spacing;
        line-height: 19.2px;
        padding-left: 0;
        position: relative;

        p:last-child {
          margin: 0;
        }

        input[type=checkbox] {
          display: flex;
          height: 16px;
          left: 0;
          margin: 3px 0 0 0;
          position: absolute;
          top: 0;
          width: 16px;

          &::before {
            background: $brand-white;
            border: 1px solid $brand-secondary;
            content: "";
            display: block;
            height: 16px;
            left: 0;
            position: absolute;
            top: 0;
            width: 16px;
          }

          &:checked:before {
            background-color: $brand-secondary;
          }

          &:checked::after {
            align-items: center;
            background: $brand-secondary;
            color: $brand-white;
            content: "\2713";
            display: block;
            display: flex;
            font-size: $font-size-sm;
            height: 16px;
            justify-content: center;
            left: 0;
            position: absolute;
            top: 0;
            width: 16px;
          }
        }
      }

      .help-block {
        color: $brand-red;
        margin-top: 0;
        top: 100%;
      }
    }
  }

  @media screen and (max-width: $screen-md) {
    > label {
      font-size: $font-size-base;
      line-height: 23.4px;
    }
  }
}

.form-group--captcha {
  align-items: center;
  display: inline-flex;
  justify-content: center;
  margin-bottom: 24px;
  margin-top: 24px;
  padding: 0;

  .google-captcha-field.has-error {
    margin: 0;
    padding: 0;
    position: absolute;
    top: 100%;

    .help-block {
      margin: 8px 0 0 0;
      position: relative;
    }
  }
}

#form_row_front_newsletter_type_newsletterSubject {
  display: none;
}

.section--searchform {
  .form-element--pattern,
  .form-element--datepicker {
    input {
      border-right: none;

      &:focus {
        border-color: $brand-primary;

        +.input-group-addon {
          border: 1px solid $brand-primary;
          border-left: none;
        }
      }
    }
  }

  .form-submit {
    @include make-button--primary-1();
    @include size(100%, 38px);
  }

  @media screen and (max-width: $screen-md-max) {
    .form-element:not(:last-of-type) {
      margin-bottom: 15px;
    }
  }
}

.datetimepicker {
  .fa-arrow-left, .fa-arrow-right {
    display: block;
    width: 100%;
    height: 100%;
    background-image: url('../../../images/svgicons/collapse-indicator.svg');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 10px;
  }

  .fa-arrow-left {
    transform: rotate(90deg);
  }

  .fa-arrow-right {
    transform: rotate(-90deg);
  }
}

.layout--show {
  &.layout--event_web_publication-show {
    .layout-hook--main {
      .section--jumbotron-event {
        background-color: $brand-bgk-highlight;

        @media screen and (max-width: $screen-md) {
          background-color: transparent;
        }
      }
    }

    .layout-hook--content {
      padding-top: 72px;
      padding-bottom: 48px;

      .content-text {
        a {
          &::before {
            content: none;
          }
        }

        ul {
          padding: 0;

          li {
            color: $text-color;
            font-family: $font-family-primary;
            padding-left: 0;

            &::before {
              content: none;
            }
          }
        }
      }

      .section--details-info {
        padding-top: 16px;

        .section-header {
          padding: 0;

          .section-title {
            color: $text-color;
            font-size: $font-size-slg;
            line-height: 36.4px;
            padding: 0;

            &::before {
              content: none;
            }
          }
        }

        .group-mediaobj {
          display: flex;
          margin: 24px 0;

          .mediaobj--eventinfo {
            width: 50%;
          }
        }
      }

      .section--program {
        margin: 0;
        padding-bottom: 48px;
        padding-top: 16px;

        .section-header {
          padding: 0;

          .section-title {
            color: $text-color;
            font-size: $font-size-slg;
            line-height: 36.4px;
            padding: 0;

            &::before {
              content: none;
            }
          }
        }

        .section-block {
          padding-top: 24px;
        }
      }

      @media screen and (max-width: $screen-md) {
        padding-top: 48px;

        .section--details-info {
          .group-mediaobj {
            flex-direction: column;
            margin: 16px 0;
            width: 100%;

            .mediaobj--eventinfo {
              width: 100%;
            }

            .mediaobj--eventinfo + .mediaobj--eventinfo {
              margin-top: 8px;
            }
          }
        }
      }
    }
  }
}

.shorten-detail {
  display: none;
}

.shorten_expand {
  .shorten-ellipses {
    display: none;
  }

  .shorten-detail {
    display: inline;
  }
}

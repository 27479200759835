.label {
  line-height: 1;
  text-decoration: none;
}

.label--tags {
  background: transparent;
  color: $brand-primary;
  font-family: $font-family-primary;
  font-size: $font-size-sm;
  font-weight: unset;
  letter-spacing: $letter-spacing;
  line-height: 14.2px;
  padding: 0;

  @media screen and (max-width: $screen-md) {
    font-size: $font-size-xs;
  }
}

.label--investors {
  border-radius: 0;
  border: 1px solid $brand-primary;
  color: $brand-primary;
  font-size: $font-size-xxs;
  padding: .25em .6em;
  text-transform: uppercase;
}

.card-element--tags .label--investors + .list--tags {
  display: inline-block;
}

.card-element--tags {
  &.element-normal--tags {
    margin-bottom: 16px;

    @media screen and (max-width: $screen-md) {
      margin-bottom: 0;
    }
  }
}

.card-element-meta {
  display: flex;

  .card-element--tags {
    flex: 1;
    padding-right: 5px;
  }
}

.element-special--tags {
  .list-item {
    .label--tags {
      font-family: $font-family-bold;

      &.always-first-tag {
        color: $brand-crimson-red;

        &:focus,
        &:hover {
          color: $brand-crimson-red;
        }
      }
    }
  }
}

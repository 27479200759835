$social-brand: (
  envelope:    $brand-primary,
  facebook:    $brand-primary,
  flickr:      $brand-primary,
  google-plus: $brand-primary,
  instagram:   $brand-primary,
  linkedin:    $brand-primary,
  pinterest:   $brand-primary,
  rss-two:     $brand-primary,
  rss:         $brand-primary,
  twitter:     $brand-primary,
  viadeo:      $brand-primary,
  youtube:     $brand-primary,
);

.share {
  padding: 10px;
  text-align: center; // For po.st to be centered.
  z-index: 100; // To be above everything always.
}

.share.share--default {
  align-items: center;
  display: flex;
  padding: 0;

  .share-title {
    color: $text-color;
    font-size: $font-size-base;
    letter-spacing: $letter-spacing;
    line-height: 23.4px;
    margin: 0 24px 0 0;
  }

  .list--share {
    display: flex;
    margin: 0 -4px;

    .list-item {
      padding: 0 4px;
    }
  }

  .share-item {
    display: block;

    .wizicon {
      @include size(36px, 36px);

      background-color: $brand-primary;
      border-radius: 0;
      padding: 8px;

      @each $social-network in envelope, facebook, flickr, google-plus, instagram, linkedin, pinterest, rss, rss-two, twitter, viadeo, youtube {
        &.wizicon-#{$social-network} {
          @include webidentity-huer(map-get($social-brand, #{$social-network}), $brand-white, $brand-white);
          fill: $brand-white;
        }
      }
    }
  }
}

.list {
  @include list-unstyled();
}

.list--vertical {
  display: flex;
  margin: 0 -4px;

  .list-item {
    padding: 0 4px;
  }
}

.list--horizontal {
  @include list-component(10px);
}

.list--tags {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -12px;

  .list-item {
    border-right: 1px solid $brand-primary;
    display: inline-flex;
    margin-bottom: 16px;
    padding: 0 12px;

    &:last-child {
      border-right: unset;
    }

    a.label--tags:focus,
    a.label--tags:hover {
      color: $brand-primary;
    }
  }
}

.list--assets {
  .list-item:first-child {
    .list-item-element {
      border-top-width: 0;
    }
  }

  .list-item-link {
    @extend .link-default;

    font-weight: 600;
  }

  .list-header-element {
    border-bottom-width: 0;
    color: $text-muted;
    font-family: $font-family-primary;
    font-size: $font-size-xs;
    font-weight: $font-weight-bold;
    text-transform: uppercase;
  }

  .list-item:nth-child(even) .list-item-element {
    background-color: $brand-light-lter;
  }

  .list-item:nth-child(odd) .list-item-element {
    background-color: $brand-light-dk;
    border-right: 1px solid $brand-white;
  }

  .list-item .list-item-element .wizicon {
    fill: $brand-primary;
  }

  .list-item-element {
    border-top-width: 0;
    padding: 12px 15px;
    vertical-align: middle;
  }

  .list-item-element--type {
    .wizicon {
      @include size(30px, 30px);
      fill: $brand-dark-lter;
    }
  }

  .list-item-element--title {
    font-weight: 600;
  }

  .list-item-element--tags {
    .list--tags {
      margin-bottom: 0;
    }
  }

  .list-item-element--date {
    white-space: nowrap;
  }
}

.list--webidentities {
  display: flex;
  margin: 0 -4px;

  .list-item {
    padding: 0 4px;
  }
}

.list--content-download-1 {
  @include list-unstyled();

  margin-bottom: 0;

  .list-item {
    @include make-font-9();

    font-family: $font-family-primary;
    padding: 10px 15px;
    text-align: center;
  }

  .list-item + .list-item {
    border-top: 1px solid $border-color;
  }
}

.list--content-download-2 {
  margin: 0 -16px;
  display: flex;
  justify-content: center;

  .list-item {
    padding: 0 16px;

    .btn--secondary {
      @include make-font-9();

      align-items: center;
      background-color: $brand-crimson-red;
      border-color: $brand-crimson-red;
      display: flex;
      justify-content: center;
      min-height: 72px;
      min-width: 280px;
      padding: 25px 32px;
      text-align: center;
      text-transform: uppercase;

      &:hover {
        background: $brand-primary;
        border-color: $brand-primary;
        color: $brand-white;
      }
    }
  }

  @media screen and (max-width: $screen-md) {
    align-items: center;
    flex-direction: column;

    .list-item {
      & + .list-item {
        margin-top: 16px;
      }
    }
  }
}

.list--event_web_publication-anchors {
  @include list-unstyled();
  text-align: center;

  .list-item {
    margin-bottom: 20px;
  }

  @media screen and (min-width: $grid-float-breakpoint) {
    .list-item {
      display: inline-block;

    }

    .list-item + .list-item {
      margin-left: 20px;
    }
  }
}

.list--event_web_publication--properties {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -12px;
  padding: 0;

  .list-item {
    padding: 0 12px;
  }

  @media screen and (max-width: $screen-md) {
    flex-direction: column;
    flex-wrap: nowrap;
    margin: 0;

    .list-item {
      padding: 0;

      & + .list-item {
        margin-top: 8px;
      }
    }
  }
}

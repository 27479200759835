.svgfilters {
  display: none; // hide the .svgfilters element, filters are referenced via CSS.
}

.layout-hook.layout-hook--content {
  .section-wrapper {
    margin-left: auto;
    margin-right: auto;
    max-width: 100%;
    width: 100%;
  }

  .section-block {
    .section-content-meta-info {
      align-items: flex-end;
      display: flex;
      margin-bottom: 40px;

      .section-element--download {
        margin: 0;
      }

      .social-sharing {
        margin-left: auto;
      }
    }
  }

  .section-composite {
    margin-bottom: 16px;
  }

  .section--collapses {
    margin-top: 24px;
  }

  @media screen and (min-width: $screen-md) {
    .section-wrapper {
      max-width: $container-lg - $grid-gutter-width - 180px;
    }
  }

  @media screen and (min-width: $screen-lg) {
    .section-wrapper {
      max-width: $container-lg - $grid-gutter-width - 168px;
    }
  }

  @media screen and (max-width: $screen-md) {
    .section-block {
      .section-content-meta-info {
        flex-direction: column;
        align-items: flex-start;

        .section-element--download {
          margin-bottom: 40px;
        }
      }
    }
  }
}

.layout--show {
  .layout-hook--main {
    .section--jumbotron-content.section--odd { // 1 is odd, first is always white!
      background-color: #fff;
    }

    .section--jumbotron-content {
      &.section--jumbotron-web_publication {
        background-color: $brand-bgk-highlight;

        .copyright {
          margin-right: 10px;
          margin-top: 5px;
          text-align: right;
        }
      }

      @media screen and (max-width: $screen-md) {
        &.section--jumbotron-web_publication {
          background-color: transparent;

          .section-wrapper {
            .section-block {
              padding-top: 0;

              .content-block {
                background-color: $brand-bgk-highlight;
                padding-top: 24px;
              }
            }
          }
        }
      }
    }

    .section {
      &:not(.section--breadcrumb):not(.section--jumbotron-content):not(.layout-hook--content):not(.section--news.has-special-background) {
        &.section--even {
          @include background-alternate($brand-white);
        }

        &.section--odd {
          @include background-alternate($brand-skyblue-opacity);
        }

        @media screen and (max-width: $screen-md) {
          &.section--even,
          &.section--odd {
            @include background-alternate($brand-white);
          }
        }
      }
    }
  }
}

.layout--show.layout--expert-show {
  .layout-hook--main {
    .section {
      &:not(.section--jumbotron-content):not(.layout-hook--content):not(.section--news.has-special-background) {
        &.section--even {
          @include background-alternate($brand-skyblue-opacity);
        }

        &.section--odd {
          @include background-alternate($brand-white);
        }

        @media screen and (max-width: $screen-md) {
          &.section--even,
          &.section--odd {
            @include background-alternate($brand-white);
          }
        }
      }
    }
  }
}

.layout--show {
  &.layout--web_publication-show {
    .breadcrumb--channel.no-always-first {
      .breadcrumb-item:nth-child(5),
      .breadcrumb-item:nth-child(6) {
        display: none;
      }
    }

    .layout-hook--content {
      padding-bottom: 40px;
      padding-top: 40px;
    }
  }

  .layout-hook--main {
    .section--jumbotron-content {
      &.section--jumbotron-asset,
      &.section--jumbotron-expert {
        background-color: $brand-bgk-highlight;
      }
    }
  }

  &.layout--expert-show {
    .layout-hook--content {
      padding-top: 72px;
      padding-bottom: 48px;

      .section-composite {
        margin-bottom: 24px;
      }

      @media screen and (max-width: $screen-md) {
        padding-top: 48px;

        .section-content-meta-info {
          .social-sharing {
            margin-top: 0;
          }
        }
      }
    }
  }

  &.layout--photo-show,
  &.layout--asset-show,
  &.layout--video-show {
    .layout-hook--content {
      padding-bottom: 48px;
      padding-top: 72px;

      .section-block {
        .section-element--content {
          margin-bottom: 0;
        }

        .section-element--download {
          margin: 48px auto 0;
        }

        .section-text {
          margin-top: 48px;

          .content-text {
            a {
              color: $text-color;
              padding: 0;
              text-decoration: none;

              &::before {
                content: none;
              }
            }
          }
        }
      }

      @media screen and (max-width: $screen-md) {
        padding-top: 48px;
      }
    }
  }

  .section--events {
    padding-bottom: 24px;
  }

  @media screen and (max-width: $screen-md) {
    .section--tags.section--highlight {
      margin-top: 48px;
    }
  }
}

.layout--show.layout--folder-show {
  .layout-hook--main {
    .section {
      &:not(.section--breadcrumb):not(.section--jumbotron-content):not(.layout-hook--content):not(.section--news.has-special-background) {
        &.section--even {
          @include background-alternate($brand-skyblue-opacity);
        }

        &.section--odd {
          @include background-alternate($brand-white);
        }

        @media screen and (max-width: $screen-md) {
          &.section--even,
          &.section--odd {
            @include background-alternate($brand-white);
          }
        }
      }
    }
  }
}

.layout--channel-show {
  .layout-hook--main {
    .section--web_publications_highlight {
      padding: 0;

      .section-container {
        max-width: 100%;
        width: 100%;
        background-size: 100%;
        background-image: url('../../../images/wp-highlight-bkg.png');
        background-repeat: no-repeat;
        padding: 0;

        .section-wrapper {
          margin: 0 auto;
          width: 100%;
        }

        @media screen and (max-width: $screen-md) {
          background-position-x: right;
          background-size: cover;
        }

        @media screen and (min-width: $screen-md) {
          .section-wrapper {
            max-width: $container-md;
          }
        }

        @media screen and (min-width: $screen-lg) {
          .section-wrapper {
            max-width: $container-lg;
          }
        }

        @media screen and (min-width: 1440px) {
          .section-wrapper {
            max-width: $container-xlg;
          }
        }
      }
    }

    .section--press-releases {
      padding-bottom: 0;
      padding-top: 21px;

      .section-footer {
        padding: 0;

        .section-btn {
          margin-top: -16px;
        }
      }
    }

    .section--lasted-news {
      .section-footer {
        padding: 0;

        .section-btn {
          margin-top: -24px;
        }
      }
    }

    .section--combine {
      padding-bottom: 24px;

      .section--clippings {
        margin: 0;
        padding-top: 40px;

        .gallery-list {
          .gallery-item {
            margin-bottom: 24px;

            &:last-child {
              margin-bottom: 0;

              .card--clipping {
                border-bottom: none;
              }
            }
          }
        }

        .section-footer {
          padding-top: 16px;
        }
      }

      &.section--combine-alt {
        padding-bottom: 72px;
        padding-top: 76px;

        .section--event_web_publications_highlight {
          margin: 0;
          padding-top: 40px;

          .gallery-list {
            .gallery-item {
              margin-bottom: 32px;

              &:last-child {
                margin-bottom: 0;
              }
            }
          }

          .section-footer {
            padding-top: 48px;
          }
        }
      }

      .right-block {
        &.has-background {
          margin: 0;

          .section-header {
            background-color: $brand-light-gray;
            padding: 40px 40px 48px;
          }

          .section-block {
            background-color: $brand-light-gray;
            padding: 0 40px 40px;
          }

          .section-footer {
            margin: 48px 0 0 0;
            padding: 0;
          }
        }
      }
    }

    @media screen and (max-width: $screen-md) {
      .section--web_publications_highlight {
        .gallery {
          .gallery-item {
            padding: 0;
          }
        }
      }

      .section--press-releases {
        padding-top: 24px;

        .section-footer {
          .section-btn {
            margin-top: -8px;
          }
        }
      }

      .section--lasted-news {
        .section-footer {
          .section-btn {
            margin-top: -8px;
          }
        }
      }

      .section--newsletter {
        &.section--newsletter-default {
          padding: 0;
        }
      }

      .section--combine {
        padding-bottom: 0;

        .section--clippings {
          padding-top: 0;

          .gallery-list {
            .gallery-item {
              margin-bottom: 32px;
            }
          }

          .section-footer {
            padding-top: 24px;
          }
        }

        &.section--combine-alt {
          padding: 0 0 48px;

          .section--event_web_publications_highlight {
            padding-top: 48px;
          }
        }

        .right-block {
          &.has-background {
            margin: 0 -16px;

            .section-header {
              padding: 32px 16px 24px;
            }

            .section-block {
              padding: 0 16px 32px;
            }

            .section-footer {
              margin-top: 32px;
              padding: 0 16px;
            }
          }
        }
      }
    }
  }
}

.layout--expert-index {
  .card--expert {
    .card-block {
      padding: 24px 0 32px 0;
    }
  }

  @media screen and (max-width: $screen-md) {
    .card--expert {
      .card-block {
        padding: 16px 0 0 0;
      }
    }
  }
}

.layout--media_review-index,
.layout--show {
  .section--clippings {
    .gallery-list {
      display: flex;
      flex-wrap: wrap;
      margin: 0 -24px;

      .gallery-item {
        margin-bottom: 48px;
        padding: 0 24px;
        width: 50%;

        &:nth-last-of-type(1),
        &:nth-last-of-type(2) {
          .card--clipping {
            border-bottom: 0;
          }
        }
      }

      @media screen and (max-width:  $screen-md) {
        margin: 0;

        .gallery-item {
          margin-bottom: 32px;
          padding: 0;
          width: 100%;

          &:last-of-type {
            .card--clipping {
              border-bottom: 0;
            }
          }
        }
      }
    }
  }
}

.layout--media_review-index {
  .section--clippings {
    &:last-of-type {
      padding-bottom: 60px;

      @media screen and (max-width: $screen-md) {
        padding-bottom: 16px;
      }
    }
  }
}

.content-text {
  // Base for vertical rythm: everything will be a multiple of this.
  $font-size: $font-size-base;
  $line-height: 1.3 * $font-size;

  // Setup base style.
  font-size: $font-size;
  line-height: $line-height;

  h2, .h2,
  h3, .h3,
  h4, .h4,
  h5, .h5
  h6, .h6 {
    line-height: 1.25;

    &:first-child {
      margin-top: 0;
    }
  }

  h2 {
    font-size: $font-size-slg;
    margin-top: 2 * $line-height;
    margin-bottom: 0.75 * $line-height;
  }

  h3 {
    font-size: $font-size-md;
    margin-top: 1.3 * $line-height;
    margin-bottom: 0.5 * $line-height;
  }

  h4 {
    font-size: $font-size-smd;
    margin-top: 1.25 * $line-height;
    margin-bottom: 0.5 * $line-height;
  }

  // Handle special-case when two titles are following each other.
  h2 + h2 {
    margin-top: 0.75 * $line-height;
  }

  h2 + h3,
  h3 + h3,
  h3 + h4,
  h4 + h4 {
    margin-top: 0.5 * $line-height;
  }

  a {
    color: $brand-royal-blue;
  }

  p {
    margin-top: 0;
    margin-bottom: 16px;

    &:has(img) {
      margin: 0 auto 24px;
      max-width: 100%;
    }
  }

  img {
    max-width: 100%;
  }

  // Type ?
  h2, h3, h4 {
    font-family: $font-family-bold;
    font-weight: unset;
  }

  strong, b {
    font-family: $font-family-bold;
    font-weight: unset;
  }

  table td,
  table th {
    padding: 5px 10px;
  }

  table {
    border: none;
    display: block;
    overflow: scroll;

    caption {
      color: $text-color;
    }

    @media screen and (max-width: $screen-md) {
      width: 640px;
    }
  }

  .table-responsive {
    border: none;
    margin: 0 auto;
    max-width: 640px;
    overflow-x: auto;
  }

  & > *:last-child {
    margin-bottom: 0;
  }

  blockquote {
    background-color: $brand-bgk-highlight;
    border: none;
    color: $brand-crimson-red;
    display: inline-block;
    font-family: $font-family-bold;
    font-size: $font-size-smd;
    letter-spacing: $letter-spacing;
    line-height: 28px;
    margin: 24px 0 32px;
    padding: 40px 40px 40px 104px;
    position: relative;
    word-wrap: break-word;

    &::before {
      background-image: url("../../../images/svgicons/quote.svg");
      background-size: 100%;
      content: "";
      height: 45px;
      left: 24px;
      position: absolute;
      top: 32px;
      width: 48px;
    }

    b,strong {
      color: $text-color;
      font-size: $font-size-base;
      letter-spacing: $letter-spacing;
      line-height: 23.4px;
      word-wrap: break-word;
      margin-bottom: 8px;
    }

    pre {
      border: none;
      color: $text-color;
      font-family: $font-family-primary;
      font-size: $font-size-sm;
      letter-spacing: $letter-spacing;
      line-height: 19.2px;
      padding: 0;
      white-space: normal;
    }
  }

  ul {
    list-style: none;
    margin: 32px 0 40px;
    padding: 0 0 0 32px;

    li {
      color: $brand-royal-blue;
      font-family: $font-family-bold;
      font-size: $font-size-base;
      letter-spacing: $letter-spacing;
      line-height: 23.4px;
      margin-bottom: 16px;
      position: relative;
      padding-left: 45px;

      &:last-child {
        margin-bottom: 0;
      }

      &::before {
        background-color: $brand-crimson-red;
        background-image: url("../../../images/svgicons/arr-right-white.svg");
        background-position: center;
        background-repeat: no-repeat;
        border-radius: 50%;
        content: "";
        height: 32px;
        left: 0;
        position: absolute;
        top: -5px;
        width: 32px;
      }
    }
  }

  a {
    color: $brand-royal-blue;
    display: inline-block;
    font-family: $font-family-bold;
    font-size: $font-size-base;
    line-height: 23.4px;
    padding-left: 30px;
    position: relative;
    text-decoration: underline;

    &::before {
      background-image: url("../../../images/svgicons/arr-up.svg");
      background-position: center;
      background-repeat: no-repeat;
      content: "";
      height: 14px;
      left: 0;
      position: absolute;
      top: 7px;
      width: 14px;
    }
  }

  @media screen and (max-width: $screen-md) {
    h2 {
      font-size: $font-size-md;
      line-height: 32px;
    }

    blockquote {
      font-size: $font-size-base;
      line-height: 24px;
      padding: 40px 40px 50px 88px;

      b,strong {
        line-height: 23.4px;
      }
    }

    ul {
      padding-left: 0;
    }
  }
}

.breadcrumb {
  padding: 0;

  .breadcrumb-item:first-child,
  .breadcrumb-item:nth-child(2) {
    display: none;
  }

  .breadcrumb-item,
  .breadcrumb-link {
    align-items: center;
    color: $brand-primary;
    display: flex;
    font-family: $font-family-primary;
    font-size: $font-size-sm;
    font-weight: unset;
    letter-spacing: $letter-spacing;
    line-height: 19.2px;
  }

  .breadcrumb-item:last-child {
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    display: -webkit-box;
    overflow: hidden;
    white-space: normal;
  }

  .breadcrumb-link {
    text-decoration: underline;
  }

  .breadcrumb-item--divider {
    padding: 0 4px;

    .wizicon {
      fill: $brand-royal-blue;
      height: 18px;
      width: 18px;
    }
  }
}

.breadcrumb--channel {
  align-items: center;
  display: flex;
  white-space: nowrap;
  word-wrap: normal;
}

.layout--folder-show,
.layout--photo-show,
.layout--video-show {
  .breadcrumb--channel {
    .breadcrumb-item:nth-child(7),
    .breadcrumb-item:nth-child(8) {
      display: none;
    }
  }
}

.section.section--breadcrumb {
  background-color: $brand-white;
  padding: 16px 0;

  .section-container {
    margin: 0 auto;
    max-width: $container-xl;
    padding-left: 83px;
    width: 100%;
  }

  @media screen and (max-width: $screen-md) {
    .section-container {
      padding-left: 16px;
    }
  }
}

.section {
  margin: $section-margin auto;
  max-width: $container-xl;
  padding: 0; // Resets CSSR padding on this, @see .section-block.
  position: $section-position;
  width: 100%;
}

.section-container {
  @extend .container;

  margin: 0 auto;
  max-width: $container-xl;
  padding: 0;

  @media screen and (min-width: 1440px) {
    width: $container-xlg;
  }

  @media screen and (max-width: $screen-md) {
    padding-left: 16px;
    padding-right: 16px;
    width: 100%;
  }
}

.section-wrapper {
  position: relative;
}

.section-block {
  background: $section-block-bg;
  position: $section-block-position;
}

.section-title {
  margin: 0;
  margin-bottom: $section-spacer-y;
}

.section-subtitle {
  margin-bottom: $section-spacer-y * (2/3);
  margin-top: 0;
}

.section-text {
  color: $text-color;
  font-family: $font-family-primary;
  font-size: $font-size-base;
  letter-spacing: $letter-spacing;
  line-height: 23.4px;
  margin: 0;
  margin-bottom: $section-spacer-y;
  word-wrap: break-word;
}

.section-block {
  padding: $section-spacer-y $section-spacer-x;

  &.section-block-grid {
    padding-bottom: 0;
  }
}

.section-btn,
.section-element {
  margin-bottom: $section-spacer-y;
}

.section-block,
.section-header,
.section-footer {
  > *:last-child {
    margin-bottom: 0;
  }
}

.section-btn {
  @extend #{$section-btn-prototype};
}

.section-img {
  &,
  img {
    display: block;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
}

.section-header,
.section-footer {
  padding: $section-cap-y $section-cap-x;

  @media screen and (max-width: $screen-md) {
    padding: 24px 0;
  }
}

.section-title {
  border-bottom: none;
  font-family: $section-title-font-family;
  font-size: $section-title-font-size;
  font-weight: $section-title-font-weight;
  letter-spacing: $letter-spacing;
  line-height: $section-title-line-height;
  text-align: $section-title-text-align;
  text-transform: unset;

  @media screen and (max-width: $screen-md) {
    font-size: $font-size-slg;
    line-height: 36.4px;
  }
}

.section-header {
  background: $section-header-bg;
}

.section-footer {
  background: $section-footer-bg;
}

.mediaobj--performer,
.mediaobj--spokesperson {
  > .mediaobj-object {
    font-size: 20px;

    > img {
        -moz-border-radius: 40px;
        -webkit-border-radius: 40px;
      border-radius: 40px;
      border: 1px solid #eee;
      width: 40px;
    }
  }
}

.mediaobj--performer {
  .mediaobj-title {
    color: $brand-primary;
    font-weight: 600;
  }
}

.mediaobj--eventinfo {
  align-items: center;
  display: flex;

  > .mediaobj-object {
    align-items: center;
    display: flex;
    margin-right: 8px;

    .wizicon {
      @include size(16px, 16px);

      &-icon-calendar {
        @include size(12px, 12px);
      }
    }
  }

  .mediaobj-body {
    font-family: $font-family-bold;
    font-size: $font-size-xs;
    letter-spacing: $letter-spacing;
    line-height: 19.2px;
    padding: 0;
    word-wrap: break-word;

    .mediaobj-title {
      font-family: $font-family-bold;
      font-size: $font-size-xs;
      letter-spacing: $letter-spacing;
      line-height: 19.2px;
      margin: 0;
      padding: 0;
      word-wrap: break-word;
    }

    p:last-child {
      margin-bottom: 0;
    }
  }

  @media screen and (max-width: $screen-md) {
    width: 100%;

    > .mediaobj-object {
      .wizicon {
        @include size(18px, 18px);

        &-icon-calendar {
          @include size(14px, 14px);
        }
      }
    }
  }
}

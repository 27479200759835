.navbar-header {
  background-color: $brand-primary;
}

.navbar-knob {
  cursor: pointer;
  display: block;
  padding: 12.5px 15px;

  .navbar-knob-icon,
  .navbar-knob-text {
    display: inline-block;
    vertical-align: middle;
  }

  .navbar-knob-text {
    padding: 0 10px;
  }
}

.navbar-knob--menu {
  float: left;
}

.navbar-knob--alternate_link,
.navbar-knob--search {
  float: right;
}

.navbar-knob {
  background-color: $brand-primary;

  .navbar-knob-icon {
    .wizicon {
      fill: #fff;
      height: 20px;
      width: 20px;
    }
  }

  .navbar-knob-text {
    color: #fff;
    font-size: $font-size-md;
    font-weight: 700;
    letter-spacing: 0.035em;
    line-height: 20px;
    text-transform: uppercase;
  }
}

.navbar--channel .nav-item--alternate_link {
  .nav-link-icon + .nav-link-text {
    margin-left: 5px;
  }
}

@media screen and (max-width: $screen-md) {
  .navbar--channel .nav-item--search {
    display: none;
  }

  .navbar--channel .nav-item--alternate_link {
    display: none;
  }

  .offie-dropdown--1 {
    transition: unset;

    &.offie--active {
      position: static;
      transition: unset;
    }
  }
}

@media screen and (min-width: $screen-md) {
  // Always show the level0 on tablet and wider devices.
  .offie-collapse--0 {
    position: static;
    opacity: 1;
    overflow: visible;
    visibility: visible;
  }

  // Always hide the knob (toggle).
  .navbar-knob {
    display: none;
  }
}

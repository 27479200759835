@mixin make-block-center($width) {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: $width;
}

@mixin make-columns($nbColumns, $gap-size) {
    -moz-columns: $nbColumns;
    -webkit-columns: $nbColumns;
  columns: $nbColumns;

    -moz-column-gap: $gap-size;
    -webkit-column-gap: $gap-size;
  column-gap: $gap-size;
}

@mixin text-line-clamp($rows) {
  display: -webkit-box;
  -webkit-line-clamp: $rows;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

@mixin image-view($ratio-w, $ratio-h) {
  height: 0;
  padding-top: calc((100% / #{$ratio-w}) * #{$ratio-h});
  position: relative;
  width: 100%;

  img {
    -o-object-fit: cover;
    height: 100%;
    left: 0;
    max-height: 100%;
    max-width: 100%;
    object-fit: cover;
    overflow: hidden;
    position: absolute;
    top: 0;
    width: 100%;
  }
}

@mixin show-follow-device($display) {
  &.show-on-mobile {
    display: none;
  }

  &.show-on-desktop {
    display: $display;
  }

  @media screen and (max-width: $screen-md) {
    &.show-on-mobile {
      display: $display;
    }

    &.show-on-desktop {
      display: none;
    }
  }
}

.section--sidebar {
  .section-title {
    font-size: $font-size-md;

    .section-title-icon {
      float: right;
      transform: rotate(-180deg);
      transition: transform .3s ease-out;

      .wizicon {
        fill: $brand-dark;
      }
    }

    & > .collapsed {
      .section-title-icon {
        transform: rotate(0deg);
        transition: transform .3s ease-out;
      }
    }
  }

  .section--features {
    .card--feature {
      @include make-card-layout--list(40px, false)
    }
  }

  .section--spokespersons {
    .section-btn {
      @include make-button--primary-1();
      padding: 10px 0;

      .section-btn-text {
        font-weight: $font-weight-regular;
      }
    }
  }

  .section--newsletter {
    margin-bottom: 30px;

    .section-title {
      border-bottom: $section-title-border;
    }

    .section-block {
      .form--name,
      .form--email {
        width: 100%;
      }

      .form-submit {
        width: 50%;
      }
    }

    .form--newsletter {
      background-color: $brand-light;
      border: 1px solid $border-color;
      margin-bottom: 0;
      padding: 25px;
    }
  }
}

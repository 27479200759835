.card {
  background-color: $card-default-background-color;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
}

.card-title {
  line-height: 1.2; // Resets something from redcurrents.css
  overflow-wrap: break-word;
}

.card-img {
  @include image-view(4, 3);

  &,
  img {
    max-width: 100%;
    width: 100%;
  }
}

.card-block > *:last-child {
  margin-bottom: 0 !important; // !important is required to ensure we don't have double space below for the last element.
}

.card-block .card-img {
  margin-bottom: $card-spacer-y;
}

.card-link {
  font-weight: $card-link-font-weight;
}

.card-btn {
  @extend #{$card-btn-prototype};

  align-items: center;
  display: flex;
  margin-left: auto;
  margin-top: auto;
  width: fit-content;

  .card-btn-icon {
    align-items: center;
    border-radius: 50%;
    border: 1px solid $brand-primary;
    display: flex;
    height: 24px;
    justify-content: center;
    margin-left: 16px;
    width: 24px;

    .wizicon {
      width: 13px;
      height: 12px
    }
  }

  .card-btn-text {
    color: $brand-primary;
    font-family: $font-family-primary;
    font-size: $font-size-base;
    letter-spacing: $letter-spacing;
    line-height: 23.4px;
    word-wrap: break-word;
  }

  &:hover {
    .card-btn-icon {
      background-color: $brand-primary;

      .wizicon {
        path {
          stroke: $brand-white;
        }
      }
    }

    .card-btn-text {
      font-family: $font-family-bold;
    }
  }

  @media screen and (max-width: $screen-md) {
    margin-top: 8px;
  }
}

// Default sizing.
@include card-sizing($card-spacer-x, $card-spacer-y);

// Section / *
.section {
  padding: 48px 0 0;

  &:has(.section-btn),
  &:has(.pagination) {
    padding-bottom: 48px;

    .section-footer {
      padding-top: 8px;
    }
  }
}

.section-title {
  color: $brand-primary;
  padding-bottom: $section-spacer-y;
  position: relative;

  &:before {
    background-color: $brand-coral;
    bottom: 0;
    content: "";
    height: 6px;
    position: absolute;
    width: 16px;
  }

  a {
    @extend .link-reset; // .link-default ?
  }

  @media screen and (max-width: $screen-md) {
    padding-bottom: 8px;

    &:before {
      height: 4px;
    }
  }
}

.section-block {
  padding: 0;
}

.section-header {
  padding-top: 0;
}

.section-footer {
  padding-bottom: 0;
  padding-top: 8px;
}

.section-composite {
  margin-bottom: $section-spacer-y; // When composite, it does not get the margin.
}

.section-block-form {
  padding-bottom: $section-spacer-y - $form-group-margin-bottom;
}

.section-reset {
  padding-bottom: 0;

  @media screen and (min-width: $screen-sm-min) {
    .section-block {
      padding-left: 0;
      padding-right: 0;
    }
  }
}

.section-text--secondary {
  color: $section-text-color-muted;
}

.section-btn {
  @include button-huer(
    $brand-primary,   // $color,
    #fff,           // $color-hover,
    transparent,      // $bg,
    $brand-primary,   // $bg-hover,
    $brand-primary,   // $border-color,
    $brand-primary,   // $border-hover,
    $brand-primary    // $color-active: $color,
  );

  align-items: center;
  border: 2px solid;
  display: inline-flex;
  justify-content: center;
  min-width: 112px;
  padding: 24px 32px;
  position: relative;
  letter-spacing: $letter-spacing;

  .section-btn-icon {
    display: none;
    vertical-align: middle;

    .wizicon {
      @include size(13px, 13px);
      fill: #fff;
    }
  }

  .section-btn-text {
    font-size: $font-size-xs;
    font-weight: unset;
    line-height: 16.8px;
    text-align: center;
    text-transform: uppercase;
    vertical-align: middle;
  }
}

.section--jumbotron-channel {
  background-color: $brand-chef-blue;
  margin-bottom: 0;
  padding: 0;

  .section-container {
    padding: 0;
    width: 100%;

    .section-wrapper {
      margin: 0 auto;
    }
  }

  @media screen and (min-width: $screen-md) {
    .section-wrapper {
      max-width: $container-md;
    }
  }

  @media screen and (min-width: $screen-lg) {
    .section-wrapper {
      max-width: $container-lg;
    }
  }

  @media screen and (min-width: 1440px) {
    .section-wrapper {
      max-width: $container-xlg;
    }
  }

  @media screen and (max-width: $screen-md) {
    display: none;

    .section-wrapper {
      width: 100%;
    }
  }
}

.section--header {
  margin-bottom: 0;
  padding: 0;

  .section-container {
    padding: 0;
    width: 100%;

    .section-wrapper {
      align-items: center;
      display: flex;
      padding-right: 56px;
      position: static;

      .header-top-mobile {
        align-items: center;
        display: flex;
        width: 100%;

        .group-section-img {
          display: flex;
        }

        .group-line-burger {
          cursor: pointer;
          display: flex;
          flex-direction: column;
          height: 30px;
          margin-left: auto;
          margin-right: 16px;
          max-width: 32px;
          position: relative;
          width: 100%;

          .line-burger {
            -webkit-transition: all ease .3s;
            background-color: $brand-primary;
            display: inline-block;
            height: 4px;
            margin: 3px 0;
            width: 100%;
          }

          &.active {
            .line-burger:first-child {
              -webkit-transform: translate(0,10px) rotate(225deg);
            }

            .line-burger:nth-child(2) {
              display: none;
            }

            .line-burger:last-child {
              -webkit-transform: translate(0, 0px) rotate(-225deg);
            }
          }
        }

        @media screen and (min-width: $screen-md) {
          display: none;
        }
      }

      .group-section-img {
        display: flex;
        flex-basis: 336px;
        margin: 0 0px;
        padding: 0 32px;
      }

      .section-block {
        align-items: center;
        display: flex;
        flex: 1;
        padding-left: 80px;
        position: static;

        .mask-scroll {
          display: flex;
          width: 100%;

          &::-webkit-scrollbar {
            display: none;
          }
        }
      }
    }

    @media screen and (max-width: $screen-md) {
      display: flex;
      padding: 0;

      .section-wrapper {
        display: inline-block;
        padding-right: 0;
        width: 100%;

        > .section-block {
          -webkit-transform: translate(-100%);
          -webkit-transition: all ease .3s;
          align-items: flex-start;
          background-color: $brand-primary;
          flex-direction: column;
          min-height: calc((var(--vh, 1vh) * 100) - 98px);
          opacity: 0;
          padding: 16px 0 10px 32px;
          position: absolute;
          top: 100%;
          visibility: hidden;
          width: 100%;
          z-index: -1;

          .mask-scroll {
            flex-direction: column;
            height: calc((var(--vh, 1vh) * 100) - 118px);
            overflow-y: scroll;
            overflow-x: clip;
            padding-right: 32px;
          }

          &.active {
            -webkit-transform: translate(0);
            opacity: 1;
            visibility: visible;
            z-index: 999;
          }
        }

        .group-section-img {
          display: none;
        }
      }
    }
  }

  .section-img {
    height: 116px;
    margin: 0;
    max-width: 336px;
    overflow: hidden;
    padding: 0;
    width: 100%;

    img {
      height: 100%;
      object-fit: cover;
      width: 100%;
    }

    @media screen and (max-width: $screen-md) {
      max-height: 98px;
      max-width: 225px;
    }
  }

  .nav--corporate {
    display: flex;
    flex: 1;
    list-style: none;
    margin-right: auto;
    margin: 0 -12px;
    padding: 0;

    > .nav-item {
      align-items: center;
      cursor: auto;
      height: 136px;
      padding: 0 12px;

      &:last-child {
        margin: 0;

        > .nav-link {
          color: $brand-white;
        }

        > .nav-link-icon {
          .wizicon path {
            fill: $brand-white;
          }
        }
      }

      > .offie-dropdown {
        display: none;
      }

      @media screen and (min-width: $screen-md) {
        cursor: pointer;

        &:last-child {
          margin-left: auto;
          margin-right: 28px;

          > .nav-link {
            color: $brand-crimson-red;
          }

          > .nav-link-icon {
            .wizicon path {
              fill: $brand-crimson-red;
            }
          }
        }

        &.js-offie {
          > .offie-dropdown.offie--active {
            display: block;
            opacity: 1;
            visibility: visible;
          }

          &:hover {
            > .nav-link {
              position: relative;

              &:after {
                background-color: $brand-crimson-red;
                background-size: 100%;
                bottom: -57px;
                content: "";
                height: 3px;
                left: 0;
                position: absolute;
                width: calc(100% + 24px);
              }
            }
          }
        }

        &:nth-child(2) {
          .offie-dropdown {
            &--1 {
              > .offie-block {
                &--1 {
                  > .center-block {
                    flex-direction: column;
                  }
                }
              }
            }
          }
        }

        &:nth-child(3) {
          .offie-dropdown {
            &--1 {
              > .offie-block {
                .right-block {
                  display: none;
                }
              }
            }
          }
        }
      }
    }

    .nav-item {
      display: flex;

      .nav-link {
        &:hover {
          background-color: transparent;
        }
      }

      .offie-dropdown {
        cursor: auto;
        left: 0;
        top: 100%;
        transition: unset;
        width: 100%;

        .nav-item {
          flex-direction: column;

          .nav-link {
            display: flex;
            font-weight: unset;
            text-transform: unset;

            @media screen and (min-width: $screen-md) {
              &:not([href=""]):not([href="#"]):hover {
                color: $brand-red;
              }
            }
          }
        }

        &--1 {
          background-color: $brand-chef-blue;

          > .offie-block {
            display: flex;
            margin: 0 auto;
            max-width: $container-xl;
            padding: 65px 0 120px 120px;
            width: 100%;

            .header-retour-menu {
              color: $brand-white;
              cursor: pointer;
              display: none;
              padding-bottom: 48px;

              .nav-link-icon {
                display: flex;
                margin: 0;

                .wizicon {
                  -webkit-transform: rotate(90deg);
                  height: 16px;
                  width: 16px;
                }
              }

              .nav-text {
                color: $brand-white;
                font-family: $font-family-bold;
                font-size: $font-size-base;
                letter-spacing: $letter-spacing;
                line-height: 19.2px;
                margin-left: 24px;
                padding: 0;
                text-transform: uppercase;
              }
            }

            > .left-block {
              flex-basis: 20.24%;
              margin-right: 24px;

              .item-title {
                color: $brand-secondary;
                font-family: $font-family-bold;
                font-size: $font-size-lg;
                letter-spacing: $letter-spacing;
                line-height: 36.4px;
                margin: 0;
                word-wrap: break-word;
              }

              p {
                color: $brand-secondary;
                font-size: $font-size-base;
                letter-spacing: $letter-spacing;
                line-height: 21.6px;
                margin: 16px 0 0;
                word-wrap: break-word;
              }

              .btn-link {
                font-size: $font-size-base;
                line-height: 21px;
                margin-top: 16px;
                padding: 0;
                text-decoration: underline;
                text-transform: unset;

                &:hover {
                  color: $brand-primary;
                }

                &:not([href=""]):not([href="#"]):hover {
                  color: $brand-red;
                }
              }
            }

            &--1 {
              > .center-block {
                display: flex;
                flex: 1;

                > .nav--channel-2 {
                  display: flex;
                  flex-direction: column;
                  flex-wrap: wrap;
                  margin-top: -8px;
                  width: 50%;

                  > .nav-item {
                    width: 100%;
                    padding: 8px 24px 8px 0;
                  }

                  &.nav-center-special {
                    flex-direction: row;
                    width: 100%;

                    > .nav-item {
                      max-width: 33%;
                      width: 100%;
                    }

                    .offie-block--3 {
                      .nav--channel-2 {
                        margin-top: 4px;
                      }
                    }
                  }
                }
              }
            }

            > .right-block {
              flex-basis: 26.6%;
              padding-left: 3.64%;
              position: relative;

              &:has(.section-img) {
                &::before {
                  background: url('../../../../images/paterne-footer.png');
                  content: "";
                  height: 100%;
                  left: 0;
                  max-height: 194px;
                  position: absolute;
                  width: 13.68%;
                }
              }

              .section-img {
                padding: 0;
                position: relative;
                width: 100%;
                max-height: 194px;
                height: 100%;

                img {
                  width: 100%;
                }
              }
            }

            .nav-item {
              .nav-link {
                font-family: $font-family-bold;
                font-size: $font-size-smd;
                line-height: 28px;
                padding: 0;
              }
            }
          }

          .offie-dropdown {
            opacity: 1;
            visibility: visible;
            position: static;

            .offie-block {
              background-color: transparent;

              .nav--channel-2 {
                margin-top: 12px;

                .nav-link {
                  padding-left: 40px;
                  position: relative;

                  &::before {
                    background: $brand-primary;
                    border-radius: 50%;
                    content: "";
                    height: 5px;
                    left: 20px;
                    position: absolute;
                    top: 9px;
                    width: 5px;
                  }

                  @media screen and (min-width: $screen-md) {
                    &:not([href=""]):not([href="#"]):hover {
                      &::before {
                        background: $brand-red;
                      }
                    }
                  }
                }
              }

              .nav-item {
                padding: 4px 24px 4px 0;

                &:last-child {
                  padding-bottom: 0;
                }

                .nav-link {
                  font-family: $font-family-primary;
                  font-size: $font-size-base;
                  line-height: 21.6px;
                  word-break: break-word;
                }
              }
            }
          }
        }

        .block-language {
          display: none;
        }
      }

      .nav-link {
        color: $brand-primary;
        font-family: $font-family-bold;
        font-size: $font-size-sm;
        letter-spacing: $letter-spacing;
        line-height: 19.2px;
        text-transform: uppercase;
        word-wrap: break-word;
      }

      .nav-link-icon {
        padding-left: 16px;

        .wizicon {
          fill: $brand-primary;
          height: $font-size-xxxs;
          width: $font-size-xxxs;
        }
      }
    }

    @media screen and (max-width: $screen-md) {
      flex-direction: column;
      flex: unset;
      margin: 0;
      width: 100%;

      .nav-item {
        height: auto;

        .nav-link {
          color: $brand-white;
          font-size: $font-size-slg;
          line-height: 23.4px;
        }

        .offie-dropdown {
          &--1 {
            -webkit-transform: translate(100%);
            -webkit-transition: all ease .5s;
            background-color: $brand-primary;
            display: inherit;
            min-height: calc(var(--vh, 1vh) * 100);
            opacity: 0;
            position: absolute;
            top: -98px;
            visibility: hidden;

            &.offie--active {
              -webkit-transform: translate(0);
              opacity: 1;
              visibility: visible;
            }

            > .offie-block {
              background-color: $brand-primary;
              flex-direction: column;
              max-height: calc(var(--vh, 1vh) * 100);
              overflow: scroll;
              padding: 48px 32px 36px;

              .header-retour-menu {
                align-items: center;
                display: flex;
                justify-content: flex-start;
              }

              > .left-block {
                border-bottom: 1px solid $brand-white;
                flex-basis: auto;
                margin-right: 0;
                padding-bottom: 24px;

                .item-title {
                  color: $brand-white;
                  font-size: $font-size-slg;
                  line-height: 23.4px;
                }

                p {
                  color: $brand-white;
                  display: none;
                  margin-top: 8px;
                }

                .btn-link {
                  color: $brand-white;
                  font-family: $font-family-primary;
                  font-size: $font-size-sm;
                  line-height: 19.2px;
                  margin-top: 8px;

                  &:hover {
                    color: $brand-white;
                  }

                  &:not([href=""]):not([href="#"]):hover {
                    color: $brand-white;
                  }
                }
              }

              &--1 {
                > .center-block {
                  flex-direction: column;
                  margin: 0;
                  padding-top: 20px;

                  > .nav--channel-2 {
                    margin: 0;
                    width: 100%;

                    > .nav-item {
                      flex-direction: column;
                      padding: 20px 0;
                      width: 100%;
                    }

                    &.nav-center-special > .nav-item {
                      max-width: 100%;
                      width: 100%;
                    }
                  }
                }
              }

              > .right-block {
                display: none;
              }
            }

            .offie-dropdown {
              .offie-block {
                .nav--channel-2 {
                  margin-top: 8px;
                }

                .nav-item {
                  padding: 8px 0;

                  .nav-link {
                    font-size: $font-size-smd;
                    line-height: 24px;
                    padding-left: 16px;

                    &::before {
                      content: none;
                    }
                  }
                }
              }
            }
          }

          .block-language {
            display: flex;
            padding-top: 32px;
          }
        }

        .nav-link-icon {
          cursor: pointer;
          margin-left: auto;
          padding: 0;

          .wizicon {
            -webkit-transform: rotate(-90deg);
            fill: $brand-white;
            height: 16px;
            width: 16px;
          }
        }

        > .nav-link-icon {
          align-items: center;
          display: flex;
          height: 40px;
          justify-content: center;
          position: absolute;
          right: 15px;
          width: 40px;
        }
      }

      > .nav-item {
        padding: 32px 0;
      }
    }
  }

  .back-to-homepage {
    align-items: center;
    display: flex;
    font-family: $font-family-bold;
    font-size: $font-size-sm;
    line-height: 19.2px;
    margin-right: 40px;
    padding-top: 2px;
    text-transform: uppercase;

    .wizicon {
      height: 12px;
      margin-left: 8px;
      width: 12px;

      path {
        fill: $brand-crimson-red;
      }
    }

    &.active {
      color: $brand-crimson-red;
    }

    @media screen and (max-width: $screen-md) {
      font-size: $font-size-slg;
      line-height: 23.4px;
      margin: 0;
      padding: 32px 0;
      width: 100%;

      .wizicon {
        -webkit-transform: rotate(-90deg);
        height: 16px;
        margin-left: auto;
        width: 16px;

        path {
          fill: $brand-white;
        }
      }

       &.active {
         color: $brand-white;
       }
    }
  }

  .group-element-right {
    align-items: center;
    display: flex;

    @media screen and (max-width: $screen-md) {
      margin-top: auto;
      padding: 32px 0;
      width: 100%;
    }
  }

  .group-header-language {
    display: flex;
    list-style: none;
    margin: 2px -4px 0;
    padding: 0;
    position: relative;

    .language-item,
    .language-link {
      color: $brand-primary;
      font-family: $font-family-primary;
      font-size: $font-size-sm;
      letter-spacing: $letter-spacing;
      line-height: 24px;

      &:hover {
        font-family: $font-family-bold;
      }
    }

    .language-item {
      padding: 0 4px;
    }

    .language-link {
      padding: 6px 2px;
    }

    .language-link-icon {
      padding-left: 10px;

      .wizicon {
        -webkit-transform: rotate(0);
        -webkit-transition: all ease .3s;
        height: $font-size-xxxs;
        width: $font-size-xxxs;

        path {
          fill: $brand-primary;
        }
      }
    }

    .language-item.active-lang .language-link {
      border-bottom: 2px solid $brand-red;
      cursor: pointer;
      font-family: $font-family-bold;
      position: relative;
    }

    @media screen and (max-width: $screen-md) {
      align-items: center;
      display: flex;
      margin: 0;

      .language-item,
      .language-link {
        color: $brand-white;
      }

      .language-link-icon {
        display: none;
      }
    }
  }

  .navbar-search-block {
    margin-left: 40px;

    .navbar-knob--search {
      .wizicon {
        height: 20px;
        margin-top: -2px;
        width: 20px;
      }
    }

    @media screen and (max-width: $screen-md) {
      margin-left: auto;

      .navbar-knob--search {
        .wizicon {
          path {
            fill: $brand-white;
          }
        }
      }
    }
  }
}

.section--header-2 {
  .section-img {
    > img {
      margin-left: 0;
    }
  }

  .section-title,
  .section-text {
    text-align: left;
  }
}

.section--header-3 {
  .section-title,
  .section-text {
    text-align: left;
  }

  @media screen and (min-width: $grid-float-breakpoint) {
    .section-img {
      margin-left: 0;
      width: 50%;

      > img {
        margin-left: 0;
      }
    }

    .section-block {
      bottom: 0px;
      position: absolute;
      right: 0;

    }

    .section-title,
    .section-text {
      text-align: right;
    }
  }
}

.page-title {
  color: $brand-primary;
  font-family: $font-family-bold;
  font-size: $font-size-xxxl;
  line-height: 56px;
  word-wrap: break-word;

  @media screen and (max-width: $screen-md) {
    font-size: $font-size-slg;
    line-height: 36.4px;
  }
}

.section--jumbotron-view {
  align-items: center;
  background-color: $brand-wisp-pink;
  background-image: url('../../../../images/bkg-jumbotron-view.png');
  background-position: center right;
  background-repeat: no-repeat;
  background-size: 120px 100%;
  display: flex;
  min-height: 260px;
  padding: 64px 0;

  &:has(.mask-box) {
    .section-container {
      max-width: 100%;
      width: 100%;
    }

    .section-block {
      padding-bottom: 0;
      padding-right: 0;

      &::before {
        content: none
      }

      .content-block {
        max-width: 100%;
        padding: 0;
      }

      @media screen and (min-width: $screen-md) {
        max-width: $container-md;

        .content-block {
          max-width: -webkit-calc(100% - 462px);
        }
      }

      @media screen and (min-width: $screen-lg) {
        max-width: $container-lg;

        .content-block {
          max-width: -webkit-calc(100% - 542px);
        }
      }

      @media screen and (min-width: 1440px) {
        max-width: $container-xlg;

        .content-block {
          max-width: -webkit-calc(100% - 462px);
        }
      }

      @media screen and (min-width: 1441px) {
        .content-block {
          max-width: -webkit-calc(100% - 502px);
        }
      }
    }

    .mask-box {
      -webkit-transform: translate(0, -50%);
      align-items: center;
      display: flex;
      margin-left: auto;
      max-width: 462px;
      position: absolute;
      right: 120px;
      top: 50%;
      width: 100%;

      .section-img {
        @include image-view(16, 9);
      }
    }

    .mask-title {
      position: relative;
      padding-bottom: 22px;

      &::before {
        background-color: $brand-coral;
        bottom: 0;
        content: "";
        height: 6px;
        position: absolute;
        width: 16px;
      }
    }
  }

  .section-block {
    margin: 0 auto;
    max-width: 100%;
    padding: 0 40px 22px 0;
    position: relative;

    &::before {
      background-color: $brand-coral;
      bottom: 0;
      content: "";
      height: 6px;
      position: absolute;
      width: 16px;
    }

    .content-block {
      max-width: 100%;
    }
  }

  .page-title {
    margin: 0;
    padding-bottom: 16px;
    word-break: break-all;
  }

  .page-description {
    color: $brand-secondary;
    font-size: $font-size-slg;
    letter-spacing: $letter-spacing;
    line-height: 36.4px;
    margin-bottom: 0;
    word-wrap: break-word;

    p {
      margin-bottom: 16px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  @media screen and (max-width: $screen-md) {
    background-image: none;
    min-height: auto;
    padding: 24px 0 32px;

    .section-container {
      padding: 0;
    }

    &:has(.mask-box) {
      padding-bottom: 0;

      .section-container{
        background-image: none;
        background-size: auto;

        .section-wrapper {
          display: flex;
          flex-direction: column;
        }
      }

      .section-block {
        padding: 0;

        .content-block {
          max-width: 100%;
          padding: 0 16px;
        }
      }

      .mask-box {
        -webkit-transform: translate(0);
        left: 0;
        margin: 32px 0 0;
        max-width: 100%;
        position: relative;
        top: 0;
      }
    }

    .section-block {
      flex-direction: column;
      padding: 0 16px 22px;

      .content-block {
        max-width: 100%;
      }
    }

    .page-description {
      font-size: $font-size-base;
      line-height: 23.4px;
    }
  }
}

.section--news {
  &.section--highlight {
    .section-block {
      padding: 0;
    }
  }

  &.section--success-story {
    background-image: url("../../../../images/bkg-stories.png");
    background-position: left top;
    background-repeat: no-repeat;
    background-size: 100% 284px;
    padding: 56px 0 0;

    .section-title {
      color: $brand-white;
    }

    .section-footer {
      padding-top: 0;
      position: absolute;
      right: 0;
      top: 0;
      z-index: 2;

      .section-btn {
        border-color: $brand-white;
        color: $brand-white;

        &:hover {
          background-color: $brand-white;
          color: $brand-primary;
        }
      }
    }

    @media screen and (max-width: $screen-md) {
      background: none;
      padding: 0;

      .section-header {
        background-image: url("../../../../images/bkg-stories.png");
        background-position: left top;
        background-repeat: no-repeat;
        margin: 0 -16px;
        padding: 32px 16px;
      }

      .section-block {
        padding-top: 32px;
      }

      .section-footer {
        padding-top: 16px;
        position: relative;

        .section-btn {
          border-color: $brand-primary;
          color: $brand-primary;

          &:hover {
            background-color: $brand-primary;
            color: $brand-white;
          }
        }
      }
    }
  }

  &.has-special-background {
    background-image: url("../../../../images/bkg-stories.png");
    background-position: left top;
    background-repeat: no-repeat;
    background-size: 100% 284px;
    padding: 56px 0 0;

    .section-title {
      color: $brand-white;
    }

    @media screen and (max-width: $screen-md) {
      background: none;
      padding: 0;

      .section-header {
        background-image: url("../../../../images/bkg-stories.png");
        background-position: left top;
        background-repeat: no-repeat;
        margin: 0 -16px;
        padding: 32px 16px;
      }

      .section-block {
        padding-top: 32px;
      }
    }
  }
}

.section--spokespersons {
  &.contacts-hp {
    margin-top: -16px;

    @media screen and (max-width: $screen-md) {
      margin: 0;
    }
  }
}

.section--videos.section--highlight {
  background-image: url('../../../../images/shape-video-bkg.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;

  .section-wrapper {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .section-title {
      color: $brand-white;
    }

    .section-block  {
      max-width: 100%;
      position: relative;
      width: 100%;
    }

    .section-btn {
      border-color: $brand-white;
      color: $brand-white;

      &:hover {
        background-color: $brand-white;
        color: $brand-primary;
      }
    }

    .section-footer {
      padding-top: 0;
    }
  }

  @media screen and (max-width: $screen-md) {
    padding: 0;

    .section-container {
      padding: 32px 0 48px;

      .section-wrapper {
        .section-header {
          padding-left: 16px;
          padding-right: 16px;
        }

        .section-footer {
          margin-top: 59px;
          padding: 0 16px;
        }
      }
    }
  }

  @media screen and (min-width: $screen-md) {
    background-size: 50%;
    padding: 0;

    .section-wrapper {
      align-items: flex-start;
      display: flex;
      flex-direction: column;
      justify-content: center;
      min-height: 360.2px;

      .section-block {
        bottom: -6%;
        margin-left: auto;
        max-width: 71.1%;
        position: absolute;
        right: 0;
      }

      .section-footer {
        padding-top: 16px;
      }
    }
  }

  @media screen and (min-width: $screen-lg) {
    .section-wrapper {
      min-height: 392.15px;

      .section-block {
        max-width: 63.4%;
      }
    }
  }

  @media screen and (min-width: 1440px) {
    .section-wrapper {
      min-height: 522px;

      .section-block {
        max-width: 69.64%;
      }
    }
  }

  @media screen and (min-width: 1441px) {
    .section-wrapper {
      min-height: 549px;

      .section-block {
        max-width: 73.1%;
      }
    }
  }
}

.section-header.section-header--with-options {
  @include clearfix();

  border-bottom: 1px solid $border-color;
  margin-bottom: $section-cap-y;
  padding-bottom: 0;

  .section-header-widget {
    display: none;

    @media screen and (min-width: $grid-float-breakpoint) {
      display: block;
      float: right;
    }
  }

  .section-title {
    border-bottom-width: 0;
    overflow: hidden;
  }
}

.section-composite {
  position: relative;
}

.section--boilerplate {
  padding: 40px 0;
  margin-bottom: 0;

  .section-img {
    margin-bottom: 10px;

    img {
      margin: auto;
      display: block;
      width: 200px;
    }
  }

  .section-title {
    @include make-font-10();
    border-bottom: 0;
  }

  .section-block {
    @include make-font-1();
    padding-top: 0;

    @media screen and (min-width: $grid-float-breakpoint) {
      @include make-columns(2, 2em);
    }
  }
}

.section-languages {
  text-align: right;

  .section-header-widget-item {
    display: inline-block;
  }
}

.layout-newsletter {
  .layout-hook--main {
    .section--newsletter {
      padding-bottom: 126px;

      .section-block {
        margin: 0 auto;
        max-width: 872px;
        width: 100%;
      }

      .form--newsletter {
        display: flex;
        flex-direction: column;
        width: 100%;
      }
    }
  }
}

.layout-hook--main {
  .alert-success {
    background-color: $brand-royal-blue;
    border-color: $brand-royal-blue;
    margin: $section-margin auto;
    max-width: $container-xl;
    position: $section-position;
    width: 100%;

    .alert-inner {
      align-items: center;
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;
    }
  }

  .alert-dismissible .close {
    -webkit-transform:rotate(45deg);
    top: -1px;

    .wizicon {
      @include size(20px, 20px);
    }
  }
}

.section--tags.section--highlight {
  background-color: $brand-royal-blue;
  background-image: url('../../../../images/bkg-highlight-tags.png');
  background-position: bottom;
  background-repeat: repeat-x;
  margin: 0 auto;
  padding: 40px 0 120px;

  .section-title {
    color: $brand-coral;
  }

  .list--tags {
    margin: 0 -8px;

    .list-item {
      align-items: center;
      border: 1px solid $brand-white;
      display: flex;
      justify-content: center;
      margin: 0 8px 16px;
      padding: 16px 12px;

      .label--tags {
        color: $brand-white;
      }

      &:hover {
        background-color: $brand-crimson-red;
        border-color: $brand-royal-blue;
      }
    }
  }

  @media screen and (max-width: $screen-md) {
    background-color: $brand-primary;
    background-image: url("../../../../images/bkg-highlight-tags-mb.png");
    background-position: left bottom;
    background-size: 100% 64px;
    margin: 0 16px;
    padding: 24px 0 84px;
    width: auto;

    .list--tags {
      .list-item {
        margin-bottom: 12px;
        padding: 8px 12px;
      }
    }
  }
}

.section--combine {
  .section-wrapper {
    > .row {
      display: flex;
      margin: 0;

      > div {
        &:first-child {
          flex: 1;
          margin-right: 56px;
        }

        &:last-child {
          flex-basis: 39%;
        }
      }
    }
  }

  .section--tags {
    &.section--highlight {
      background-position-x: 21%;
      background-position-y: bottom;
      padding-left: 40px;
      padding-right: 40px;
    }
  }

  @media screen and (max-width: $screen-md) {
    .section-wrapper {
      > .row {
        flex-direction: column;

        > div {
          &:first-child {
            flex: auto;
            margin: 0;
          }

          &:last-child {
            flex-basis: auto;
            padding-top: 48px;
          }
        }
      }
    }

    .section--tags {
      &.section--highlight {
        background-position: left bottom;
        margin: 0 auto;
        padding-left: 16px;
        padding-right: 16px;
      }
    }
  }
}

.layout-hook--footer {
  .sectio--main-footer {
    padding: 24px 0 40px;

    .section-container {
      @media screen and (min-width: $screen-md) {
        width: $container-md;
      }

      @media screen and (min-width: $screen-lg) {
        width: $container-lg;
      }

      @media screen and (min-width: $container-xl) {
        width: 1200px;
      }

      .section-wrapper {
        > .row {
          margin: 0;
          display: flex;

          > div:first-child {
            flex: 1;
          }

          > div:last-child {
            flex-basis: 282px;
          }

          @media screen and (max-width: 1249px) {
            flex-direction: column;
            margin: 0 auto;
            max-width: 375px;

            > div:first-child {
              flex: unset;
            }

            > div:last-child {
              flex-basis: auto;
              padding: 0;
            }
          }
        }

        .left-block {
          display: flex;
          margin-bottom: 0;

          .block--footer-img {
            display: flex;
            flex-basis: 384px;
            justify-content: space-around;
            margin: 0;
            padding: 0;

            .section-img {
              align-items: center;
              display: flex;
              margin: 0;
              max-width: 384px;
              overflow: hidden;
              padding: 0;
              width: 100%;

              &:first-child {
                max-width: 148px;
              }

              &:last-child {
                max-width: 177px;
              }

              .wizicon {
                height: auto;
                width: 100%;
              }
            }
          }

          .block--footer-corporate {
            flex: 1;
            padding: 0 24px;

            .list-item {
              display: flex;
              flex-wrap: wrap;
              list-style: none;
              margin: 0;
              max-width: 384px;
              padding: 0;

              .nav-item {
                display: flex;
                width: 50%;

                .nav-link {
                  color: $brand-secondary;
                  font-size: $font-size-sm;
                  letter-spacing: $letter-spacing;
                  line-height: 24px;
                  position: relative;
                  word-wrap: break-word;
                }

                .nav-link-text {
                  -webkit-transition: all ease .3s;
                }

                .nav-link-icon {
                  -webkit-transition: all ease .3s;
                  left: 0;
                  opacity: 0;
                  position: absolute;
                  top: 5px;
                  visibility: hidden;

                  .wizicon {
                    width: 12px;
                    height: 12px;
                  }
                }

                &:hover {
                  .nav-link-text {
                    -webkit-transform: translate(20px, 0);
                  }

                  .nav-link-icon {
                    opacity: 1;
                    visibility: visible;
                  }
                }
              }
            }
          }
        }

        .right-block {
          margin-bottom: 0;

          .section-header {
            padding-bottom: 24px;

            .section-title {
              color: $brand-secondary;
              font-size: $font-size-smd;
              line-height: 28px;
              padding-bottom: 24px;
              word-wrap: break-word;
            }
          }
        }

        @media screen and (max-width: 1249px) {
          .left-block {
            flex-direction: column;

            .block--footer-img {
              flex-basis: auto;
              justify-content: space-between;
              margin-bottom: 20px;
              margin-right: 0;
              padding: 0;
            }

            .block--footer-corporate {
              flex: unset;
              margin-bottom: 24px;
              padding: 0;

              .list-item {
                max-width: 100%;

                .nav-item {
                  margin-bottom: 16px;
                  width: 100%;

                  .nav-link {
                    font-size: $font-size-base;
                    line-height: 23px;
                  }
                }
              }
            }
          }

          .right-block {
            margin-bottom: 28px;
            padding-top: 8px;

            .section-header {
              max-width: 100%;
              padding-bottom: 16px;
            }
          }
        }
      }
    }

    .section--webidentities {
      .list--webidentities {
        align-items: center;
        display: flex;
        margin: 0 -12px;

        .list-item {
          padding: 0 12px;

          .webidentity {
            background-color: transparent;
            border-color: transparent;
            border-radius: unset;
            height: 24px;
            padding: 0;
            width: 24px;

            .wizicon {
              path {
                fill: $brand-royal-blue;
              }
            }

            &-twitter {
              background-color: $brand-royal-blue;
              border-radius: 2px;
              height: 20px;
              padding: 3px;
              width: 20px;

              .wizicon {
                path {
                  fill: $brand-white;
                }
              }
            }
          }
        }
      }
    }

    @media screen and (max-width: 1249px) {
      padding-bottom: 48px;
    }
  }
}

.section--newsletter.section--newsletter-default {
  align-items: center;
  border: none;
  display: flex;
  padding: 24px 0;

  .section-container {
    align-items: center;
    background-color: $brand-red;
    background-image: url("../../../../images/bkg-newsletter.svg");
    background-position: left center;
    background-repeat: no-repeat;
    background-size: 178px 100%;
    display: flex;
    min-height: 158px;

    .section-wrapper {
      align-items: center;
      display: flex;
      padding-left: 250px;
      width: 100%;

      .section-title {
        color: $brand-white;
        font-size: $font-size-xlmd;
        line-height: 48px;
        margin: 0;
        padding-bottom: 14px;
        word-break: break-word;

        &:before {
          background-color: $brand-white;
        }
      }

      .section-header {
        padding: 0;
      }

      .section-footer {
        margin: 0 160px 0 auto;
        padding: 0;
      }

      .section-btn {
        background-color: $brand-primary;
        border-color: $brand-primary;
        color: $brand-white;
        display: inline-flex;
        gap: 16px;
        max-height: 49px;
        padding-left: 24px;
        padding-right: 24px;

        &:hover {
          background-color: $brand-coral;
          border-color: $brand-coral;
        }

        .section-btn-icon {
          display: block;

          .wizicon {
            @include size(20px, 20px);

            fill: $brand-white;
          }
        }
      }
    }
  }

  @media screen and (max-width: $screen-md) {
    .section-container {
      background-image: none;
      margin: 0 16px;
      min-height: auto;
      padding: 64px 0 0 0;
      position: relative;

      &:after {
        background-image: url("../../../../images/bkg-newsletter-mobile.svg");
        background-repeat: no-repeat;
        background-size: 100%;
        content: "";
        height: 64px;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
      }

      .section-wrapper {
        align-items: flex-start;
        flex-direction: column;
        padding: 48px 16px;

        .section-title {
          font-size: $font-size-lgmd;
          line-height: 36px;
        }

        .section-footer {
          margin: 24px auto 0;
          width: 100%;
        }

        .section-btn {
          width: 100%;
        }
      }
    }
  }
}

.layout--asset-index {
  .layout-hook--main {
    .section {
      &:not(.section--jumbotron-view):not(.section--jumbotron-channel) {
        &.section--even {
          @include background-alternate ($brand-skyblue-opacity);
        }

        &.section--odd {
          @include background-alternate ($brand-white);
        }

        .section-footer {
          text-align: center;
        }
      }
    }
  }
}

.layout-tag {
  .layout-hook--main {
    .section {
      &:not(.section--jumbotron-view):not(.section--jumbotron-channel) {
        &.section--even {
          background-color: $brand-skyblue-opacity;
        }

        &.section--odd {
          background-color: $brand-white;
        }

        .section-footer {
          text-align: center;
        }
      }
    }
  }
}

.section--contactform {
  .card--contactform {
    margin: 0 auto;
    max-width: 872px;
    width: 100%;

    .card-block {
      padding: 0;
      width: 100%;

      .js-contact-form {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
      }
    }

    #form_row_front_message_type_ask {
      display: none;
    }
  }
}

.section--main,
.section--sidebar {
  margin-bottom: 0;
}

.section--jumbotron-content {
  padding-top: 0;

  .section-container {
    .section-wrapper {
      .section-block {
        min-height: 470px;

        .content-block {
          width: 100%;
          padding: 40px 3.7% 52px 0;

          .section-element--tags {
            margin-bottom: 0;

            .label--tags {
              font-family: $font-family-bold;
            }

            .list--tags {
              .list-item {
                &:first-child {
                  .label--tags.always-first-tag {
                    color: $brand-crimson-red;
                  }
                }
              }
            }
          }

          .mask-title {
            position: relative;
            padding-bottom: 22px;

            &::before {
              background-color: $brand-coral;
              bottom: 0;
              content: "";
              height: 6px;
              position: absolute;
              width: 16px;
            }
          }

          :has(.section-highlight-text) .section-title {
            margin-bottom: 16px;
          }

          .section-title {
            font-size: $font-size-xxxl;
            line-height: 56px;
            margin-bottom: 0;
            padding: 0;

            &::before {
              content: none;
            }
          }

          .section-subtitle {
            color: $text-color;
            font-family: $font-family-primary;
            font-size: $font-size-base;
            letter-spacing: $letter-spacing;
            line-height: 23.4px;
            margin: 24px 0 0;
          }

          .section-highlight-text {
            color: $text-color;
            font-family: $font-family-primary;
            font-size: $font-size-slg;
            letter-spacing: $letter-spacing;
            line-height: 36.4px;
            margin: 0;

            p {
              margin: 0;
            }

            strong, b {
              font-family: $font-family-bold;
              font-weight: unset;
            }
          }

          @media screen and (min-width: 1441px) {
            max-width: 60%;
          }

          @media screen and (max-width: 1440px) {
            max-width: 62%;
          }

          @media screen and (max-width: 1367px) {
            max-width: 63%;
          }
        }

        .mask-card-img {
          -webkit-transform: translate(0, -50%);
          max-width: 44.44%;
          position: absolute;
          right: calc((100vw - $container-lg) / 2 * -1);
          top: 50%;
          width: 100%;
          z-index: 3;

          .section-img {
            @include image-view(16, 9);
          }

          @media screen and (min-width: $screen-md) {
            right: calc((100vw - $container-md) / 2 * -1);
          }

          @media screen and (min-width: $screen-lg) {
            right: calc((100vw - $container-lg) / 2 * -1);
          }

          @media screen and (min-width: $container-xl) {
            right: -83px;
          }
        }
      }
    }

    @media screen and (max-width: $screen-md) {
      padding: 0;

      .section-wrapper {
        .section-block {
          display: flex;
          flex-direction: column;
          min-height: auto;
          padding: 24px 0 0;

          .content-block {
            max-width: 100%;
            padding: 0 16px 32px;

            .section-title {
              font-size: $font-size-slg;
              line-height: 36.4px;
            }

            .section-highlight-text {
              font-size: $font-size-base;
              line-height: 23.4px;
            }

            .section-subtitle {
              font-size: $font-size-sm;
              line-height: 19.2px;
            }
          }

          .mask-card-img {
            -webkit-transform: translate(0);
            max-width: 100%;
            position: relative;
            right: 0;
            top: 0;
          }
        }
      }
    }
  }
}

.section--jumbotron-asset {
  .section-container {
    width: auto;
    padding: 40px 56px 48px 83px;

    .section-wrapper {
      .section-block {
        min-height: auto;

        .content-block {
          max-width: 100%;
          padding: 0;

          .breadcrumb--channel {
            margin-bottom: 40px;
          }

          .section-subtitle {
            margin-top: 16px;

            .section-subtitle-date {
              margin: 0;
            }
          }

          .social-sharing {
            display: flex;
            justify-content: flex-end;
            margin-top: 56px;
          }
        }
      }
    }
  }

  @media screen and (max-width: $screen-md) {
    .section-container {
      padding: 0 0 32px;

      .section-wrapper {
        .section-block {
          .content-block {
            padding-left: 16px;
            padding-right: 16px;

            .breadcrumb--channel {
              padding: 0;
            }

            .social-sharing {
              margin-top: 40px;
            }
          }
        }
      }
    }
  }
}

.section--jumbotron-folder {
  .section-container {
    width: 100%;
    padding: 0;

    .section-wrapper {
      .section-block {
        display: flex;
        min-height: auto;

        .content-block {
          display: flex;
          flex-direction: column;
          max-width: 100%;
          padding: 40px 56px 62px 120px;

          .breadcrumb--channel {
            margin-bottom: 80px;
            padding-top: 0;
          }

          .social-sharing {
            margin-top: auto;
          }
        }

        .mask-box {
          max-width: 638px;
          width: 100%;

          .section-img {
            @include image-view(4, 3);
          }

          &.show-on-desktop {
            display: block;
          }

          &.show-on-mobile {
            display: none;
          }
        }
      }
    }

    @media screen and (max-width: $screen-md) {
      .section-wrapper {
        .section-block {
          .content-block {
            padding: 0 16px 32px;

            .breadcrumb--channel {
              margin-bottom: 40px;
              padding: 0;
            }

            .social-sharing {
              margin-top: 40px;
            }
          }

          .mask-box {
            max-width: 100%;

            .section-img {
              padding-top: calc(100% / 16 * 9);
            }

            &.show-on-desktop {
              display: none;
            }

            &.show-on-mobile {
              display: block;
              margin: 40px -16px 0;
              max-width: calc(100% + 32px);
              width: calc(100% + 32px);
            }
          }
        }
      }
    }
  }
}

.section--jumbotron-event {
  background-color: $brand-bgk-highlight;
  padding: 0; // @TODO

  .section-container {
    width: 100%;

    .section-wrapper {
      .section-block {
        display: flex;
        min-height: auto;

        .content-block {
          display: flex;
          flex-direction: column;
          max-width: 100%;
          padding: 40px 56px 50px 83px;

          .breadcrumb--channel {
            margin-bottom: 40px;
            padding-top: 0;
          }

          .section-element--tags {
            margin: 0;
          }

          .mask-title {
            position: relative;
            padding-bottom: 22px;

            &::before {
              background-color: $brand-coral;
              bottom: 0;
              content: "";
              height: 6px;
              position: absolute;
              width: 16px;
            }
          }

          .social-sharing {
            display: flex;
            margin: auto 0 0 auto;
          }

          .section-element--properties {
            margin: 16px 0 0 0;
          }

          .section-subtitle {
            margin-top: 16px;
            font-size: $font-size-slg;
            line-height: 36.4px;
          }

          .section-element--webidentites {
            margin: 24px 0 0;
          }
        }

        .mask-box {
          max-width: 638px;
          width: 100%;
          position: relative;

          .section-img {
            @include image-view(4, 3);
          }

          .section-text--state {
            background-color: $brand-royal-blue;
            color: $brand-white;
            font-family: $font-family-bold;
            font-size: $font-size-base;
            left: 0;
            line-height: 19.2px;
            margin: 0;
            padding: 16px;
            position: absolute;
            top: 0;
            z-index: 2;
          }
        }

        .social-sharing.show-on-mobile {
          display: none;
        }
      }
    }
  }

  .mediaobj--eventinfo {
    align-items: center;
    display: flex;

    .mediaobj-object {
      display: flex;
      flex-basis: 12px;
    }

    .mediaobj-body {
      color: $text-color;
      flex: 1;
      font-family: $font-family-bold;
      font-size: $font-size-xs;
      letter-spacing: $letter-spacing;
      line-height: 19.2px;
      padding: 0;
      word-wrap: break-word;
    }
  }

  @media screen and (max-width: $screen-md) {
    background-color: transparent;

    .section-container {
      .section-wrapper {
        .section-block {
          padding-top: 0;

          .content-block {
            background-color: $brand-bgk-highlight;
            padding: 24px 16px 32px;

            .breadcrumb--channel {
              padding: 0;
            }

            .social-sharing {
              display: none;
            }
          }

          .mask-box {
            max-width: 100%;
          }

          .social-sharing.show-on-mobile {
            display: flex;
            margin: 48px 16px 0 auto;
          }
        }
      }
    }

    .mediaobj--eventinfo {
      .mediaobj-object {
        flex-basis: 18px;
      }
    }
  }
}

.section--jumbotron-expert {
  .section-container {
    width: 100%;

    .section-wrapper {
      .section-block {
        display: flex;
        min-height: auto;

        .content-block {
          max-width: 100%;
          padding: 40px 56px 24px 83px;

          .breadcrumb--channel {
            padding-top: 0;
            margin-bottom: 80px;
          }

          .section-subtitle {
            margin-top: 16px;
            font-size: $font-size-slg;
            line-height: 36.4px;
          }

          .section-element--webidentites {
            margin: 24px 0 0;
          }
        }

        .mask-box {
          max-width: 638px;
          width: 100%;

          .section-img {
            @include image-view(4, 3);
          }
        }
      }
    }

    @media screen and (max-width: $screen-md) {
      .section-wrapper {
        .section-block {
          .content-block {
            padding: 0 16px 32px;

            .breadcrumb--channel {
              padding: 0;
              margin-bottom: 40px;
            }

            .section-subtitle {
              font-size: $font-size-base;
              line-height: 23.4px;
            }
          }

          .mask-box {
            max-width: 100%;
          }
        }
      }
    }
  }
}

.section--searchform {
  background-color: $brand-light;
  border: 1px solid $border-color;
  margin-bottom: 0;
  padding: 0;

  .section-block {
    padding-bottom: $section-spacer-y;
    padding-top: $section-spacer-y;
  }
}

.section--default.section--webidentities {
  .section-header {
    padding-bottom: 0;
  }

  .section-title {
    border-bottom: 0;
    font-size: $font-size-lg;
    text-align: center;
  }

  .list--webidentities {
    text-align: center;
  }
}

.section--experts_highlight {
  padding-bottom: 0;

  & + .section.section--experts {
    padding-top: 0;
  }
}

.section--events {
  .gallery-list {
    .gallery-item:last-child {
      .card--event {
        border: 0;
      }
    }
  }
}

.wiztrust-block {
  align-items: center;
  background: $brand-light-gray;
  display: inline-flex;
  gap: 32px;
  justify-content: flex-start;
  padding: 24px;

  .fingerprint {
    .wizicon {
      @include size(190px, 56px);
    }
  }

  .content-block {
    align-items: flex-start;
    display: inline-flex;
    flex-direction: column;
    flex: 1 1 0;
    gap: 8px;
    justify-content: flex-start;

    .content-title {
      @include make-font-2();

      color: $brand-primary;
      letter-spacing: $letter-spacing;
      margin: 0;
      word-wrap: break-word;
    }

    .content-text {
      @include make-font-14();

      align-self: stretch;
      color: $brand-secondary;
      margin: 0;

      a {
        color: $brand-secondary;
        font-family: $font-family-primary;
        padding: 0;
        text-decoration: none;

        &::before {
          content: none;
        }
      }
    }
  }

  @media screen and (max-width: $screen-md) {
    flex-direction: column;
    justify-content: center;
  }
}

.section--wiztrust_alt {
  padding: 32px 0 40px;

  @media screen and (max-width: $screen-md) {
    padding-top: 16px;
  }
}

.section--powered-by {
  padding: 0;

  .section-container {
    padding: 0;
    width: 100%;
  }

  .section-block {
    padding: 24px 60px;
    width: auto;
  }

  .section-text {
    a {
      p, span {
        display: inline-block;
        vertical-align: bottom;
      }

      p {
        font-size: 12px;
        font-family: $font-family-secondary;
        margin: 0 5px 0 0;
        text-decoration: underline;
      }

      span {
        width: 90px;

        img {
          width: 100%;
          height: auto;
        }
      }
    }
  }
}

.section-wrapper .section--features--generic {
  position: absolute;
  right: -80px;
  top: 180px;
  width: 80px;

  .card--feature {
    margin-bottom: 10px;
    text-align: center;

    .card-img {
      float: none;
      margin: auto;
      margin-bottom: $card-spacer-y;
      width: initial;

      img {
        @include size(45px, 45px);
      }
    }

    .card-title {
      color: $brand-secondary;
      font-size: $font-size-xxs;
      font-weight: $font-weight-bold;
      position: static;
      text-transform: uppercase;
      transform: initial;
    }
  }

  @media screen and (max-width: $grid-float-breakpoint) {
    display: none;
  }
}

.layout-search {
  .section--jumbotron-search {
    .section-block {
      padding-bottom: 8px;
    }
  }

  .section--any {
    overflow: hidden;
    width: 100%;
  }

  .tab-content {
    .tab-pane {
      .section-composite {
        .section-composite {
          padding: 48px 0 8px;

          &::before {
            content: '';
            height: 100%;
            left: -50%;
            position: absolute;
            top: 0;
            width: 200%;
          }

          &:nth-child(odd) {
            &::before {
              background-color: $brand-skyblue-opacity;
            }
          }

          &:nth-child(even) {
            &::before {
              background-color: $brand-white;
            }
          }
        }
      }
    }
  }
}

.layout--event_web_publication-index {
  .section--events {
    &:last-of-type {
      padding-bottom: 106px;

      @media screen and (max-width: $screen-md) {
        padding-bottom: 56px;
      }
    }
  }
}

.content-text--blockchain a {
  text-decoration: underline;
}

.med-nav-access {
  background-color: $brand-royal-blue;
  color: $brand-white;
  cursor: pointer;
  display: none;
  font-family: $font-family-primary;
  font-size: $font-size-sm;
  left: 1px;
  padding: 8px;
  position: absolute;
  top: 0;
  transition: all .2s cubic-bezier(.2,0,0,1);
  z-index: 2;

  &:focus {
    z-index: 1081;
  }

  &:focus,
  &:hover {
    background: $brand-primary;
    color: $brand-white;
    text-decoration: none;
  }
}

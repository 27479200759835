/**
 * COOKIES
 * @see jquery-cookiescuttr
 */

.cc-cookies,
.cc-cookie-accept {
  color: #7e8083;
  display: none;
}

.cc-cookies {
  background-color: #fff;
  border-bottom: 1px solid $border-color;
  border-top: 1px solid $border-color;

  padding: 20px 50px 20px 25px;
  position: fixed; // Important for the thing to show.
  width: 100%;
  z-index: 1001;
}

.cc-cookie-accept {
  position: absolute;
  top: 22.5px;
  right: 22.5px;

  .wizicon {
    display: block;
    fill: #7e8083;
  }

  &:focus,
  &:hover {
    color: $text-color;

    .wizicon {
      fill: $text-color;
    }
  }
}

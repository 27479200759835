@font-face {
  font-family: 'Outfit-Regular';
  font-style: normal;
  src: url('../../../fonts/Outfit-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Outfit-Light';
  font-style: normal;
  src: url('../../../fonts/Outfit-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'Outfit-Bold';
  font-style: normal;
  src: url('../../../fonts/Outfit-Bold.ttf') format('truetype');
}

.pagination {
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 0;

  > li {
    & > a,
    & > span {
      background-color: transparent !important;
      border: none;
      color: $brand-secondary !important;
      font-family: $font-family-bold;
      font-size: $font-size-sm !important;
      line-height: 23px !important;
      padding: 0;
    }
  }

  .pagination-item {
    .pagination-link {
      display: flex;
      padding: 0 4px;

      .wizicon {
        width: 20px;
        height: 20px;

        path {
          fill: $brand-royal-blue;
        }
      }
    }

    .pagination-text {
      padding: 0 20px;
    }
  }
}
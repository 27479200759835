.panel {
  background-color: transparent;
  border-width: 0;

  .panel-heading {
    padding: 0;

    a {
      align-items: center;
      cursor: pointer;
      display: flex;
      padding: 16px 32px;
    }
  }

  .panel-title {
    color: $brand-white;
    flex: 1;
    font-family: $font-family-bold;
    font-size: $font-size-slg;
    letter-spacing: $letter-spacing;
    line-height: 36.4px;
    overflow: hidden; // this makes the clearfix from floated.
    padding-right: 16px;
    padding: 0;
  }

  .panel-title-text {
    margin: 0;
    display: inline-block;
    vertical-align: middle;
  }

  .panel-title-icon {
    display: inline-block;
    line-height: 1;
    vertical-align: middle;
  }

  .panel-text {
    color: $text-color;
    font-family: $font-family-primary;
    margin: 0;
  }

  .panel-widget-item {
    display: flex;
    padding: 0;
    vertical-align: top; // Tricky thing to get rid of inline-block space.
  }

  @media screen and (max-width: $screen-md) {
    .panel-title {
      font-size: $font-size-base;
      line-height: 23.4px;
    }
  }
}

.collapse-body,
.panel-body {
  @include clearfix();
}

.panel-group .panel + .panel {
  margin-top: 16px; // Resets bootstrap.
}
